<template>
  <v-card v-if="isLoading == false" class="planning-disponibilite" color="#C8E6C9">
    <v-dialog v-model="isModalsOpen['eventSavModal'].value" persistent>
      <modal-sav :data="isModalsOpen['eventSavModal'].data" :eventColorByType="eventColorByType"
        @closeModal="closeModal('eventSavModal')"></modal-sav>
    </v-dialog>
    <v-dialog v-model="isModalsOpen['dateModal'].value" persistent>
      <modal-date :data="isModalsOpen['dateModal'].data" :eventColorByType="eventColorByType"
        @closeModal="closeModal('dateModal')"></modal-date>
    </v-dialog>
    <v-dialog v-model="isModalsOpen['eventModal'].value" location="center" persistent>
      <modal-materiel-state @closeStatesModal="isModalsOpen['eventModal'].value = false"
        :data="isModalsOpen['eventModal'].data" @close="closeModal('eventModal')">
      </modal-materiel-state>
      <!-- <modal-event
        :data="isModalsOpen['eventModal'].data"
        @closeModal="closeModal('eventModal')"
      ></modal-event> -->
    </v-dialog>
    <v-dialog v-model="isModalsOpen['settingsModal'].value" persistent>
      <modal-settings :eventColorByType="eventColorByType" :daysToHide="daysToHide"
        @closeSettingsModal="closeSettingsModal"></modal-settings>
    </v-dialog>
    <div style="position: absolute; top: 112px; width: 100%; z-index: 0">
      <v-row class="ma-1" align="center">
        <v-col class="px-1">
          <v-combobox :style="{ filter: calendarIsOpening ? 'blur(5px)' : '' }" :disabled="calendarIsOpening" clearable
            variant="outlined" v-model="ressourceSelected" :items="getRessourceList"
            no-data-text="Aucune donnée pour cette recherche" placeholder="Sélectionner une Sous famille / Matricule"
            hide-details hide-selected multiple chips closable-chips style="background-color: white"
            :menu-props="{ maxHeight: 500 }">
          </v-combobox>
        </v-col>
      </v-row>
    </div>
    <full-calendar ref="calendarDispo" style="margin-top: 10px; margin-bottom: 10px"
      :style="{ filter: calendarIsOpening ? 'blur(5px)' : '' }" class="mx-2" :options="calendarOptions">
    </full-calendar>
    <div v-if="calendarIsOpening == true" style=" position: absolute; height:100%; width: 100%; top:0px;
        background-color:rgb(0,0,0,0.5) ;z-index: 3; padding-top:40vh;" align="center">
      <v-progress-circular size="120" indeterminate color="black">Chargement</v-progress-circular>
    </div>
    <div style="position: absolute; top: 83vh; right: 20px; z-index: 1">
      <v-col>
        <v-row>
          <v-col style="
              background-color: #e0e0e0;
              border-style: none;
              padding: 5px;
              border-radius: 10px;
              box-shadow: 1px 1px 10px 2px grey;
            " cols="auto">
            <button v-for="(value, key) in eventColorByType" :key="key" :style="{ 'background-color': value.color }"
              style="
                font-size: 15px;
                margin: 5px;
                border: solid 1px grey;
                padding: 10px;
                border-style: none;
                border-radius: 5px;
                color: black;
              ">
              <v-chip style="
                  background-color: rgb(0, 0, 0, 0.5);
                  color: white;
                  cursor: pointer;
                ">
                {{ value.label }}
              </v-chip>
            </button>
          </v-col>
          <v-col style="
              background-color: #e0e0e0;
              border-style: none;
              padding: 5px;
              border-radius: 10px;
              box-shadow: 1px 1px 10px 2px grey;
            " v-if="eventColorState && Object.keys(eventColorState).length > 0" class="ml-2" cols="auto">
            <button v-for="(value, key) in eventColorState" :key="key" :style="{ 'background-color': value.color }"
              style="
                font-size: 15px;
                margin: 5px;
                border: solid 1px grey;
                padding: 10px;
                border-style: none;
                border-radius: 5px;
                color: black;
              ">
              <v-chip style="
                  background-color: rgb(0, 0, 0, 0.5);
                  color: white;
                  cursor: pointer;
                ">
                {{ value.label }}
              </v-chip>
            </button>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-card>
  <div v-else style=" position: absolute; height:100%; width: 100%; top:0px;
        background-color:rgb(0,0,0,0.5); z-index: 3; padding-top:40vh;" align="center">
    <v-progress-circular size="120" indeterminate color="white">Chargement</v-progress-circular>
  </div>
</template>

<script>
import mainEngine from "@/core/index";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import calendarData from "../hooks/index";
import engine from "../core/index";
import modalDate from "@/projects/planning-disponibilite/components/modal-date.vue";
// import modalEvent from "@/projects/planning-disponibilite/components/modal-event.vue";
import ModalMaterielState from "@/components/modal-materiel-state.vue";
import modalSettings from "@/components/modal-planning-settings.vue";
import modalSav from "@/projects/planning-disponibilite/components/modal-sav.vue";

export default {
  data() {
    return {
      engine: engine,
      ressourceSelected: [],
      isLoading: true,
      user: {},
    };
  },
  setup() {
    const {
      calendarOptions,
      resourceIds,
      setSubFamilyToShow,
      isModalsOpen,
      setModal,
      eventColorByType,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
      isEventsLoaded,
      isMobile,
      calendarIsOpening,
      contratState,
      eventColorState
      // setNewScreenSize
    } = calendarData();
    return {
      calendarOptions,
      resourceIds,
      setSubFamilyToShow,
      isModalsOpen,
      setModal,
      eventColorByType,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
      isEventsLoaded,
      isMobile,
      calendarIsOpening,
      contratState,
      eventColorState
      // setNewScreenSize
    };
  },
  mounted() {
    this.isMobile = this.$vuetify.display.mobile;
    this.setCalendarView()
    // this.onResize()
    // this.$nextTick(() => {
    //   window.addEventListener('resize', this.onResize);
    // })
    this.isUserConnected();
  },
  methods: {
    setCalendarView() {
      let that = this
      let view = ""

      if (this.$refs && this.$refs.calendarDispo) {
        if (this.isLittleScreen) {
          view = "mobile"
          this.fullView = false
        } else {
          view = "resourceTimeline7Day"
          this.fullView = true
        }

        let calendarApi = this.$refs.calendarDispo.getApi()
        calendarApi.changeView(view);
      } else {
        setTimeout(function () {
          that.setCalendarView()
        }, 250)
      }
    },
    // onResize() {
    // this.setNewScreenSize(window.innerWidth)
    // },
    closeSettingsModal(data) {
      let that = this;
      let days = data.daysListe.filter(
        (val) => !data.daysToShow.includes(val.idx)
      );
      if (days) {
        days = days.map((val) => val.idx);
        engine.save_day_to_hide(days.toString(), function () {
          that.updateCalendar();
        });
      }
      engine.save_calendar_colors(
        JSON.stringify(data.localColortab),
        function () {
          that.updateCalendar();
        }
      );
      this.closeModal("settingsModal");
    },
    isUserConnected() {
      let that = this;
      let token = localStorage.getItem("userToken");
      mainEngine.get_user_by_token(token, function (data) {
        if (data.code != 0) {
          // localStorage.clear();
          localStorage.setItem("userToken", undefined);
        }
        that.isLoading = false;
      });
    },
    updateCalendar() {
      this.updateCalendarColors();
      this.updateCalendarDaysToShow();
    },
    closeModal(modalKey) {
      this.isModalsOpen[modalKey].value = false;
    },
  },
  watch: {
    isLittleScreen(val) {
      this.isMobile = val;
      this.setCalendarView()
    },
    // isMobileCmp(val) {
    //   this.isMobile = val;
    // },
    ressourceSelected(val) {
      if (val.length > 3) {
        this.ressourceSelected.pop();
      }
      this.setSubFamilyToShow(val);
    },
  },
  computed: {
    isMobileCmp() {
      return this.$vuetify.display.mobile;
    },
    isLittleScreen() {
      return this.$vuetify.display.smAndDown;
    },
    getRessourceList() {
      return [...new Set(this.resourceIds.map((val) => val.subFamily))].sort();
    },
  },
  components: {
    FullCalendar,
    modalDate,
    // modalEvent,
    ModalMaterielState,
    modalSettings,
    modalSav,
  },
};
</script>

<style>
@import "../style.css";
</style>