<template>
  <div class="planning-technicien">
    <div class="pas-2">
      <div style="font-size: 20px">
        <div v-if="isModalChangeEventStateOpen" class="modal" align="center">
          <div class="modal-content" align="left" style="width: 700px; padding: 20px; margin-top: 20%">
            <div>
              L'intervention {{ modaleChangeEventState.num }} à déja été envoyé
              au technicien(s) êtes vous sur de vouloir la modifier ?
            </div>
            <div style="margin-top: 10px; width: 100%" align="center">
              <button @click="
                (isModalChangeEventStateOpen = false),
                verifEditEventFromModal({
                  e: modaleChangeEventState.e,
                  Techniciens_Nom: modaleChangeEventState.calendarTech,
                })
                " class="modal-btn" style="background-color: #4caf50; width: 40%">
                Oui
              </button>
              <button @click="isModalChangeEventStateOpen = false" class="modal-btn"
                style="background-color: #bf360c; width: 40%">
                Non
              </button>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="isModalOptionOpen" width="99%" max-width="800">
        <v-card>
          <modal-options :isRoot="isRoot" :technicienList="technicienList" :commentaireListe="commentaireListe"
            :setCommentaires="setCommentaires" @close="isModalOptionOpen = false"></modal-options>
        </v-card>
      </v-dialog>
      <v-dialog v-model="isModalSettingsOpen" persistent>
        <modal-settings :eventColorByType="eventColorByIdFiltered" :daysToHide="daysToHide"
          @closeSettingsModal="closeSettingsModal"></modal-settings>
      </v-dialog>
      <v-dialog v-model="isModalSelectionTechnicienOpen" width="99%" max-width="800">
        <modal-selection-technicien :isRoot="isRoot" :technicienList="technicienList"
          :technicienListAvailable="technicienListAvailable" :setTechnicienAvailable="setTechnicienAvailable"
          :addOrRmvTechnicien="addOrRmvTechnicien" :updateTechToShow="updateTechToShow"
          :setSelectedTechniciens="setSelectedTechniciens" :selectedTechniciens="selectedTechniciens"
          @close="isModalSelectionTechnicienOpen = false"
          @save="editSelectionnedTechnicien($event)"></modal-selection-technicien>
      </v-dialog>
      <v-dialog v-model="isModalTechnicienOpen">
        <modal-technicien :data="modaleTechnicienData" :technicienList="technicienList"
          :planningSettings="planningSettings" @close="closeModalEvent" @save="verifEditEventFromModal($event)">
        </modal-technicien>
      </v-dialog>
      <v-dialog v-model="isModalEventOpen" width="99%" max-width="800">
        <modal-event :event-data="selectedEvent" :optionsPlanning="optionsPlanning" :eventColorById="eventColorById"
          :eventColorByIdFiltered="eventColorByIdFiltered" :isRoot="isRoot" @close="closeModalEvent"
          @save="editEventFromModal"></modal-event>
      </v-dialog>
      <div v-if="fullView == true" id="external-events" :style="{ visibility: !isDataLoaded ? 'hidden' : 'visible' }">
        <p align="center" style=" position: relative;">
          <strong>
            <h2 style="width: 100;" class="adaptive-text">
              Interventions
              <v-btn variant="tonal"
                style="position: absolute; right: 0px;top:5px;z-index: 2;  cursor: pointer; border-radius: 200px;"
                density="compact" @click="refreshData" icon="mdi-reload">
              </v-btn>
            </h2>
          </strong>
        </p>
        <div class="adaptive-text" align="center" style="
            margin: 10px 0px;
            border-top: solid 1px;
            padding: 5px 0px;
            border-bottom: solid 1px;
          " v-if="eventsList">
          <h3 style="margin: 0px">
            {{ getEventsListFiltered.length }} /
            {{ eventsList.length }} interventions
          </h3>
        </div>
        <div>
          <div style="
              position: relative;
              border-bottom: solid 1px #b0bec5;
              padding-bottom: 3px;
            ">
            <v-text-field placeholder="Recherche d'intervention" v-model="filter" hide-details density="compact"
              variant="solo" style="background-color: white"></v-text-field>
            <div style="position: relative">
              <v-select clearable class="my-2" hide-details label="Selectionner une agence" density="compact"
                v-model="selectedAgency" variant="solo" :items="agencyList" item-value="id" item-title="text">
              </v-select>
            </div>
            <v-btn :disabled="!isRoot" class="mb-1" style="
                  padding: 5px 8.5px;
                  background-color: white;
                  cursor: pointer;
                " @click="isModalSelectionTechnicienOpen = true">
              Gestion des techniciens
            </v-btn>
          </div>
          <v-btn class="mb-2" :disabled="!isRoot" style="
                  padding: 5px 8.5px;
                  background-color: white;
                  cursor: pointer;
                " @click="isModalOptionOpen = true">
            Ajouter un commentaire
          </v-btn>
          <div class="pa-1 mb-1" style="padding-top: 3px; overflow: auto; height: 61.9vh;background-color: #F5F5F5;">
            <!-- <div class="pa-1 mb-1" style="padding-top: 3px; overflow: auto; background-color: #F5F5F5;"> -->
            <div v-for="(elem, idx) in getEventsListFiltered" :key="idx" @mouseover="setSelectedEvent(elem)"
              style="padding: 1px 0px">
              <div style="
                  border: solid 1px transparent;
                  margin: 2px 0px;
                  margin-right: 2px;
                  border-radius: 3px;
                  padding: 5px;
                  color: white;
                " :style="elem.id == selectedEvent.id
                  ? 'border: solid 2px white;background-color:' +
                  eventColorById[elem.Statut].color
                  : 'background-color:' + eventColorById[elem.Statut].color
                  " :class="elem.id == selectedEvent.id ? 'fc-event' : ''">
                <div align="right" style="color: red; position: relative">
                  <v-btn density="compact" :disabled="!isRoot" variant="text" style="
                      position: absolute;
                      right: 0px;
                      cursor: pointer;
                      z-index: 3;
                    " icon="mdi-close" @click="removeEventFromList(elem)">
                  </v-btn>
                </div>
                <div>
                  <strong>
                    <div class="fc-event-main">
                      {{ elem["N° RAP COMP"] }} (
                      {{ elem["Technicien"].join(" - ") }} )
                    </div>
                    <div class="fc-event-main" style="color: #e6ee9c">
                      {{ elem["societeliv"] }} <br />
                    </div>
                  </strong>
                  <div class="fc-event-main">
                    {{ elem["villeliv"] }}
                    {{ elem["adliv"] }}
                  </div>
                  <div class="fc-event-main">{{ elem["Theme devis"] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="calendar-container pa-2" class="hideDot" v-if="isDataLoaded">
        <!-- ref="fullCalendar"
          id="calendar" -->
        <full-calendar class="hideDot" ref="calendarTech" :options="calendarOptions" />
      </div>
      <div v-else align="center" style="padding-top: 20%">
        <!-- <div class="loader"></div> -->
        <v-progress-circular size="120" indeterminate color="black">Chargement</v-progress-circular>
      </div>
      <div v-if="errorMessage" style="position: absolute; width: 100%; bottom: 2vh; z-index: 3" align="center">
        <div style="
            background-color: #d50000;
            width: 500px;
            padding: 10px;
            border-radius: 7px;
            color: white;
            font-size: 15px;
          ">
          <div v-for="elem in errorMessage" :key="elem">
            {{ elem }}
          </div>
        </div>
      </div>
    </div>
    <v-btn color="#5C6BC0" :style="{ 'top': parseInt(calendarOptions.height.replace('vh', '')) - 4 + 'vh' }"
      style="color:white; margin-left: 10px; position:absolute;right:7px;"
      v-if="fullView == false && showLegende == false" @click="showLegende = true">
      Légende
    </v-btn>
    <div :style="{ 'top': fullView ? '' : parseInt(calendarOptions.height.replace('vh', '')) - 22 + 'vh' }" style="position: absolute;
        bottom: 3vh;
        right: 25px;
        z-index: 1;
">
      <v-hover v-if="showLegende == true || fullView" v-slot="{ isHovering, props }" open-delay="0">
        <div @click="showLegende = false" v-bind="props" :style="{ 'opacity': isHovering && fullView ? '0' : '1' }"
          style="
        background-color: #e0e0e0;
        border-style: none;
        padding: 5px;
        margin-left: 10px;
        border-radius: 10px;
        box-shadow: 1px 1px 10px 2px grey;
      ">
          <!-- <button v-for="elem in Object.values(eventColorByIdFiltered).filter((val) =>
            (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 0 && val.key != '120')) || (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 1)"
            :key="elem.key" :style="{ 'background-color': eventColorByIdFiltered[elem.key].color }" style="
          font-size: 15px;
          margin: 5px;
          border: solid 1px grey;
          padding: 5px;
          border-style: none;
          border-radius: 5px;
          color: black;
        ">
            <v-chip style="
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            cursor: pointer;
          ">
              {{ elem.label }}
            </v-chip>
          </button> -->
          <button v-for="elem in (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 1)
            ? Object.values(eventColorByIdFiltered) : Object.values(eventColorByIdFiltered).filter((val) =>
              (optionsPlanning.find((val2) => val2.key == 'use_accen_tablette').value == 0 && val.key != '120'))"
            :key="elem.key" :style="{ 'background-color': eventColorByIdFiltered[elem.key].color }" style="
          font-size: 15px;
          margin: 5px;
          border: solid 1px grey;
          padding: 5px;
          border-style: none;
          border-radius: 5px;
          color: black;
        ">
            <v-chip style="
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            cursor: pointer;
          ">
              {{ elem.label }}
            </v-chip>
          </button>
        </div>
      </v-hover>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import planningTechnicien from "../core/index";

import ModalEvent from "./modal-event.vue";
import ModalTechnicien from "./modal-technicien.vue";
import ModalSelectionTechnicien from "./modal-selection-technicien.vue";
import ModalOptions from "./modal-options.vue";
import ModalSettings from "@/components/modal-planning-settings.vue";

import calendarData from "../hooks/index";
import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";

import moment from "moment";
import engine from "../core/index";

export default {
  components: {
    FullCalendar,
    ModalEvent,
    ModalTechnicien,
    ModalSelectionTechnicien,
    ModalSettings,
    ModalOptions
  },
  setup() {
    const showOnlyState2 = ref(false);
    const modaleTechnicienData = ref({});
    const modaleChangeEventState = ref({});
    const isModalTechnicienOpen = ref(false);
    const isModalOptionOpen = ref(false);
    const isModalChangeEventStateOpen = ref(false);
    const isModalSelectionTechnicienOpen = ref(false);
    const connectionError = ref(false);
    const filter = ref("");
    const userMail = ref(undefined);
    const userPassword = ref(undefined);
    const fullView = ref(true);
    const showLegende = ref(false);

    const handleEventReceive = function (e) {
      if (isRoot.value) {
        if (
          isGoodEventSelected(e, selectedEvent) &&
          isEditableEvent(e, selectedEvent.value)
        ) {
          editEventFromCalendar(e, undefined, true);
        }
      } else {
        showErrorMessage(
          "ERREUR : Cette action nécessite un compte administrateur"
        );
      }
      e.revert();
    };
    const handleEventDragTime = function (e) {
      if (isRoot.value) {
        let event = e.event.extendedProps.event;
        selectedEvent.value = event;
        if (isEditableEvent(e, selectedEvent.value))
          editEventFromCalendar(e, undefined, false);
      } else {
        showErrorMessage(
          "ERREUR : Cette action nécessite un compte administrateur"
        );
        e.revert();
      }
    };
    const handleEventClick = function (e) {
      if (e.event.display != "background") {
        let event = e.event.extendedProps.event;
        selectedEvent.value = event;
        isModalEventOpen.value = true;
      } else {
        if (e.event.extendedProps.TYPE != undefined)
          showErrorMessage(
            "INDISPONIBILITÉ : " + e.event._def.resourceIds,
            moment(e.event.start).lang("fr").format("LLLL") +
            " / " +
            moment(e.event.end).lang("fr").format("LLLL")
          );
      }
    };
    const handleEventDrop = function (e) {
      if (isRoot.value) {
        let event = e.event.extendedProps.event;
        selectedEvent.value = event;
        if (isEditableEvent(e, selectedEvent.value))
          editEventFromCalendar(e, undefined, false);
      } else {
        showErrorMessage(
          "ERREUR : Cette action nécessite un compte administrateur"
        );
        e.revert();
      }
    };
    const isGoodEventSelected = function (eventData, selectedEvent) {
      let eventTitle = eventData.event._def.title;
      let selectedEventTitle =
        selectedEvent.value["N° RAP COMP"] +
        "( " +
        String(selectedEvent.value["Technicien"]).replaceAll(",", " - ") +
        " )" +
        selectedEvent.value["societeliv"] +
        selectedEvent.value["villeliv"] +
        selectedEvent.value["adliv"] +
        selectedEvent.value["Theme devis"];

      if (
        (eventTitle.replaceAll("\n", "").replaceAll(" ", "").replaceAll("\t", "") ==
          "✖" + selectedEventTitle.replaceAll("\r", "").replaceAll(" ", "").replaceAll("\t", "")) || (
          eventTitle.replaceAll("\n", "").replaceAll(" ", "").replaceAll("\t", "") ==
          selectedEventTitle.replaceAll("\r", "").replaceAll(" ", "").replaceAll("\t", "")
        )
      )
        return true;
      else {
        showErrorMessage("ERREUR : " + selectedEvent.value["N° RAP COMP"]);
        return false;
      }
    };

    const isEditableEvent = function (e, event) {
      if (event.Statut != 140) {
        if (event.Technicien.join("-") != e.event._def.resourceIds.join("-")) {
          if (isTechnicienAvailable(e, event, e.event._def.resourceIds)) {
            isModalTechnicienOpen.value = true;
            modaleTechnicienData.value = {
              e: e,
              num: event["N° RAP COMP"],
              eventId: event.id,
              eventTech: event.Technicien,
              calendarTech: e.event._def.resourceIds,
              technicienListAvailable: technicienListAvailable,
            };
          }
          e.revert();
          return false;
        }
        if (isTechnicienAvailable(e, event, event.Technicien)) {
          if (event.Statut == 120 || event.Statut == 130) {
            isModalChangeEventStateOpen.value = true;
            modaleChangeEventState.value = {
              e: e,
              num: event["N° RAP COMP"],
              calendarTech: e.event._def.resourceIds,
            };
            e.revert();
            return false;
          }
          return true;
        }
        return false;
      } else {
        e.revert();
        showErrorMessage(
          "ERREUR : Intervention " +
          selectedEvent.value["N° RAP COMP"] +
          " est terminé n'est donc plus modifiable."
        );
        return false;
      }
    };

    const removeEventFromList = function (item) {
      editEventFromModal({ id: item.id, Statut: "99", Debut: "", Fin: "" });
    };

    const setSelectedTechniciens = function () {
      if (selectedTechniciens.value.length === technicienList.value.length) {
        selectedTechniciens.value = [];
      } else {
        selectedTechniciens.value = technicienList.value.map((val) => val.id);
      }
    };

    const addOrRmvTechnicien = function (technicienName) {
      if (selectedTechniciens.value.includes(technicienName)) {
        let index = selectedTechniciens.value.indexOf(technicienName);
        selectedTechniciens.value.splice(index, 1);
      } else {
        selectedTechniciens.value.push(technicienName);
      }
    };

    const closeModalEvent = function () {
      isModalEventOpen.value = false;
      isModalTechnicienOpen.value = false;
    };

    const updateTechToShow = function () {
      let token = localStorage.getItem("userToken");
      planningTechnicien.setTechToShow(
        token,
        selectedTechniciens.value.join(","),
        function () { }
      );
    };

    const getTechToShow = function () {
      planningTechnicien.getTechToShow(
        userMail.value,
        userPassword.value,
        function (data) {
          if (data.code == 0) {
            if (data.data == "") {
              selectedTechniciens.value = technicienList.value.map(
                (val) => val.title
              );
            } else selectedTechniciens.value = data.data.split(",");
          }
        }
      );
    };

    const verifEditEventFromModal = function (eventEdited) {
      this.editEventFromCalendar(
        eventEdited.e,
        eventEdited.Techniciens_Nom,
        true
      );
      isModalTechnicienOpen.value = false;
      isModalEventOpen.value = false;
    };

    const editEventFromModal = function (eventEdited) {
      planningTechnicien.set_event(
        eventEdited.id,
        eventEdited,
        function (data) {
          if (data.code == 0) {
            initEvents();
          }
        }
      );
      isModalTechnicienOpen.value = false;
      isModalEventOpen.value = false;
    };

    const setAgency = function (agencyId) {
      planningTechnicien.setAgency(
        userMail.value,
        userPassword.value,
        agencyId,
        function () { }
      );
    };

    const getAgency = function () {
      planningTechnicien.getAgency(
        userMail.value,
        userPassword.value,
        function (data) {
          if (data.code == 0 && data.data != "") {
            selectedAgency.value = data.data;
          }
        }
      );
    };

    const getEventsListFiltered = computed(function () {
      if (eventsList.value.length == 0) return [];
      let filteredData = eventsList.value.filter((val) =>
        [
          val["Technicien"]
            .filter((val) => val != null)
            .join(" - ")
            .toLowerCase(),
          val["N° RAP COMP"].toLowerCase(),
          val["adliv"].toLowerCase(),
          val["societeliv"].toLowerCase(),
          val["villeliv"].toLowerCase(),
        ]
          .join(" ")
          .includes(filter.value.toLowerCase())
      );
      if (selectedAgency.value)
        filteredData = filteredData.filter(
          (val) => val.ID_Agence == selectedAgency.value
        );
      if (showOnlyState2.value == true) {
        filteredData = filteredData.filter((val) => val.Statut == "120");
      }
      return filteredData;
    });

    watch(filter, (val) => {
      if (val[0] == " ") filter.value = filter.value.trim();
    });

    const {
      eventsList,
      calendarOptions,
      selectedEvent,
      isModalEventOpen,
      eventsCalendar,
      editEventFromCalendar,
      isDataLoaded,
      eventColorById,
      initEvents,
      calendarDate,
      refreshData,
      isConnected,
      selectedAgency,
      agencyList,
      technicienList,
      selectedTechniciens,
      technicienListAvailable,
      setTechnicienAvailable,
      isTechnicienAvailable,
      showErrorMessage,
      errorMessage,
      planningSettings,
      isRoot,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
      isModalSettingsOpen,
      commentaireListe,
      setCommentaires,
      eventColorByIdFiltered,
      optionsPlanning
    } = calendarData(
      handleEventClick,
      handleEventDrop,
      handleEventDragTime,
      handleEventReceive,
      getTechToShow,
      setAgency,
      getAgency,
      fullView,
    );
    return {
      getEventsListFiltered,
      calendarOptions,
      selectedEvent,
      isModalEventOpen,
      editEventFromCalendar,
      eventsCalendar,
      errorMessage,
      isDataLoaded,
      eventColorById,
      filter,
      editEventFromModal,
      closeModalEvent,
      calendarDate,
      isModalTechnicienOpen,
      modaleTechnicienData,
      verifEditEventFromModal,
      refreshData,
      isConnected,
      userPassword,
      userMail,
      connectionError,
      eventsList,
      agencyList,
      selectedAgency,
      isRoot,
      technicienList,
      selectedTechniciens,
      addOrRmvTechnicien,
      isModalSelectionTechnicienOpen,
      setSelectedTechniciens,
      showOnlyState2,
      isModalChangeEventStateOpen,
      modaleChangeEventState,
      removeEventFromList,
      technicienListAvailable,
      setTechnicienAvailable,
      updateTechToShow,
      planningSettings,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
      isModalSettingsOpen,
      isModalOptionOpen,
      commentaireListe,
      setCommentaires,
      fullView,
      showLegende,
      eventColorByIdFiltered,
      optionsPlanning
    };
  },
  mounted() {
    this.setCalendarView()
  },
  watch: {
    isMobile() {
      this.setCalendarView()
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.xs
    }
  },
  methods: {
    setCalendarView() {
      let that = this
      let view = ""

      if (this.$refs && this.$refs.calendarTech) {
        if (this.isMobile) {
          view = "listWeek"
          this.fullView = false
        } else {
          view = "resourceTimelineWeek"
          this.fullView = true
        }

        let calendarApi = this.$refs.calendarTech.getApi()
        calendarApi.changeView(view);
      } else {
        setTimeout(function () {
          that.setCalendarView()
        }, 250)
      }
    },
    closeSettingsModal(data) {
      let that = this;
      let days = data.daysListe.filter(
        (val) => !data.daysToShow.includes(val.idx)
      );
      if (days) {
        days = days.map((val) => val.idx);
        engine.save_day_to_hide(days.toString(), function () {
          that.updateCalendarDaysToShow();
        });
      }
      engine.save_calendar_colors(
        JSON.stringify(data.localColortab),
        function () {
          that.updateCalendarColors();
        }
      );
      this.isModalSettingsOpen = false;
    },
    editSelectionnedTechnicien(data) {
      this.selectedTechniciens = data;
    },
    setSelectedEvent(elem) {
      this.selectedEvent = elem;
    },
  },
};
</script>

<style>
@import "../style.css";

.planning-technicien #external-events {
  width: 20%;
  float: left;
  max-width: 250px;
  padding: 0 5px;
  margin-right: 5px;
  /* margin: 10px; */
  /* height: 94.8vh; */
  color: black;
  background: #c8e6c9;
  border-radius: 3px;
  border: solid 1px #dcedc8 !important;
}

.planning-technicien #external-events .fc-event {
  cursor: move;
  margin: 3px 0;
}

.planning-technicien #calendar-container {
  /* margin: 10px; */
  padding-top: 10px;
}

.planning-technicien #calendar {
  border: solid 1px lightgrey;
  border-radius: 3px;
  padding: 5px;
}


.planning-technicien .hideDot .fc-list-event-graphic {
  background: white;
  min-width: 0px;
  width: 0px;
  padding-left: 0px !important;
}

.planning-technicien .hideDot .fc-list-event-time {
  background: white;
}

.planning-technicien .hideDot .fc-list-event-title {
  background: white;
}
</style>