<template>
  <v-dialog v-model="isExelOpen">
    <v-card>
      <v-col align="center" v-if="!exelIsDownloading">
        <vue-excel-editor ref="grid" readonly v-model="getTabItemsFiltered" :disable-panel-setting='false'
          :disable-panel-filter="true" no-header-edit no-footer>
          <vue-excel-column v-for="elem in getExelElemToShow" :key="elem" :field="elem.key" :label="elem.label"
            type="string" width="150px"></vue-excel-column>
        </vue-excel-editor>
        <v-btn class="mt-2 mr-3" @click="exelIsDownloading = !exelIsDownloading" style="color:white"
          color="#7986CB">Afficher / cacher Jour numérique</v-btn>
        <v-btn class="mt-2" @click="exportAsExcel"> Exporter </v-btn>
      </v-col>
      <v-col align="center" v-else>
        <vue-excel-editor ref="grid" readonly v-model="getTabItemsFiltered" :disable-panel-setting='false'
          :disable-panel-filter="true" no-header-edit no-footer>
          <vue-excel-column v-for="elem in getExelElemToDownload" :key="elem" :field="elem.key" :label="elem.label"
            type="string" width="150px"></vue-excel-column>
        </vue-excel-editor>
        <v-btn class="mt-2 mr-3" @click="exelIsDownloading = !exelIsDownloading" style="color:white"
          color="#7986CB">Afficher / cacher Jour numérique</v-btn>
        <v-btn class="mt-2" @click="exportAsExcel"> Exporter </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isModalDataOpen" width="90%" max-width="800">
    <modal-event :agencyList="agencyList" :rdvTypeListe="rdvTypeListe" :modalData="modalData"
      :resetFunction="updateDispoGestion" @closeModal="isModalDataOpen = false">
    </modal-event>
  </v-dialog>
  <v-dialog v-model="isDeleteOpen" width="auto">
    <v-card align="center" class="pa-2">
      <h3 class="pa-2">Valider la suppression</h3>
      <v-col>
        <v-row justify="center" align="center">
          <v-btn class="ma-1" color="#EF5350" style="color:white"
            @click="deleteFunction(), isDeleteOpen = false">OUI</v-btn>
          <!-- <v-btn class="ma-1" color="#EF5350" style="color:white" @click="valideDelete()">OUI</v-btn> -->
          <v-btn class="ma-1" color="#7986CB" style="" variant="outlined" @click="cancelDelete()">NON</v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isDeleterecurrenceOpen" v-if="selectedDispoToDelete" width="auto">
    <v-card align="center" class="pa-2">
      <h3 class="pa-2">Valider la suppression de l'ensemble des récurrences</h3>
      <v-col>
        <v-row justify="center" align="center">
          <v-btn class="ma-1" color="#EF5350" style="color:white"
            @click="openDeleteModal(valideDeleteRecurrence)">OUI</v-btn>
          <v-btn class="ma-1" color="#7986CB" style="" variant="outlined" @click="cancelDeleteRecurrence()">NON</v-btn>
        </v-row>
      </v-col>
      <h3 class="pa-2" v-if="selectedDispoToDelete">Valider la suppression de l'ensemble des récurrences supérieur au :
        <span style="color:#EF5350">
          {{ selectedDispoToDelete.day.toUpperCase() }}
        </span>
      </h3>
      <v-col>
        <v-row justify="center" align="center">
          <v-btn class="ma-1" color="#EF5350" style="color:white"
            @click="openDeleteModal(valideDeleteFutureRecurrence)">OUI</v-btn>
          <v-btn class="ma-1" color="#7986CB" style="" variant="outlined" @click="cancelDeleteRecurrence()">NON</v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="creatDispoIsOpen" width="90%" max-width="800">
    <v-card color="#EEEEEE">
      <v-col cols="12"
        style="font-weight: 700;text-transform: uppercase;border: solid 1px lightgrey;height:91.1vh; overflow:auto;"
        class="py-0 px-0">
        <div style="width:100%;color:white;background-color:#5C6BC0;" class="py-2" align="center">
          <h3 class=""> Créer une disponibilité
          </h3>
        </div>
        <v-col class="pa-2">
          <v-col class="pa-1">
            <v-card class="pa-2" color="">
              Sélectionner une agence
              <v-col>
                <v-select variant="outlined" hide-details v-model="gestionSelectedAgency" :items="agencyList"
                  item-title="long" label="Choisir une ville" return-object></v-select>
              </v-col>
            </v-card>
          </v-col>
          <v-col class="pa-1" v-if="gestionSelectedAgency">
            <v-card class="pa-2">
              Sélectionner une date
              <DatePicker :timePicker="false" :format="'dd/MM/yyyy'" :modelType="'timestamp'" locale="fr"
                :enableTimePicker="false" v-model="gestionSelectedDay" autoApply></DatePicker>
              <v-checkbox label="Disponibilité hebdomadaire" style="text-transform: none;"
                v-model="isHebdomadaireSelected" hide-details>
              </v-checkbox>
            </v-card>

          </v-col>
          <v-col v-if="gestionSelectedDay" class="pa-1">
            <v-card class="pa-2">
              Type de disponibilité
              <v-select style="background-color:white;text-transform: none;" :items="rdvTypeListe" item-title="label"
                item-value="KEY" variant="outlined" hide-details density="compact" v-model="selectedRdvType" chips
                closable-chips multiple return-object>
              </v-select>
            </v-card>
          </v-col>
          <v-col v-if="isHebdomadaireSelected && gestionSelectedDay" class="pa-1">
            <v-card class="pa-2">
              Sélectionner une date de fin
              <DatePicker class="mb-4" :timePicker="false" :format="'dd/MM/yyyy'" :modelType="'timestamp'" locale="fr"
                :enableTimePicker="false" v-model="gestionSelectedDayEnd" autoApply
                :disabledWeekDays="getDisabledWeekDays" :minDate="getdateFromTimestamp(gestionSelectedDay)">
              </DatePicker>
              <v-select variant="outlined" density="compact" type="number" label="Interval de répétition"
                style="background-color:white;" hide-details v-model="repetitionInterval" required
                :items="[1, 2, 3, 4, 5]">
              </v-select>
              <div style="text-transform: none;font-weight:normal;" class="mt-2">
                Etendue de la récurence {{ getNbrWeek + 1 }} semaine(s)
              </div>
            </v-card>
          </v-col>
        </v-col>
        <v-col class="pt-0" align="center"
          v-if="(gestionSelectedDay && isHebdomadaireSelected == false) || (gestionSelectedDay && gestionSelectedDayEnd && isHebdomadaireSelected)">
          <!-- {{ gestionSelectedTime }} -->
          <v-card
            :style="(gestionSelectedTime.length == 1) ? 'height:auto' : 'height:300px;overflow:auto;border:solid 2px lightgrey'"
            :class="(gestionSelectedTime.length == 1) ? '' : 'pa-2'" class="mb-1" color="transparent" variant="flat">
            <v-card class="pa-2 mb-2" v-for="elem, idx in gestionSelectedTime.length" :key="idx">
              <v-btn v-if="(gestionSelectedTime.length > 1)" variant="text" density="compact"
                style="position:absolute;right:10px;top:10px;cursor:pointer" @click="removeGestionTimeCard(idx)">
                <v-icon color="#EF5350" size="20">
                  mdi-minus-circle</v-icon>
              </v-btn>
              <v-col>
                Début disponibilité
                <DatePicker :timePicker="true" v-model="gestionSelectedTime[idx].start" :textInput="true" autoApply
                  :style="gestionSelectedTime[idx].start ? 'border:solid 2px green' : 'border:solid 2px red'">
                </DatePicker>
              </v-col>
              <v-col>
                Fin disponibilité
                <DatePicker :timePicker="true" v-model="gestionSelectedTime[idx].end" :textInput="true" autoApply
                  :style="gestionSelectedTime[idx].end ? 'border:solid 2px green' : 'border:solid 2px red'">
                </DatePicker>
              </v-col>
            </v-card>
          </v-card>
          <v-btn variant="text" :icon="calendar" @click="addGestionTimeCard()"> <v-icon color="#43A047" size="40">
              mdi-plus-circle</v-icon> </v-btn>
        </v-col>
        <v-col align="center">
          <v-btn v-if="(gestionSelectedDay && gestionSelectedTimeIsGood)" color="#66BB6A" class=""
            style="color: white; border-radius: 0px" @click="addDispo()">Ajouter</v-btn>
        </v-col>
      </v-col>
      <v-btn width="100%" variant="flat" color="#E57373" style="color: white;border-radius: 0px;"
        @click="creatDispoIsOpen = false">Fermer
      </v-btn>
    </v-card>
  </v-dialog>
  <v-card height="100vh">
    <v-card-title>Gestion des disponibilités <v-btn v-if="isAdmin" @click="creatDispoIsOpen = true" class="ml-5"
        color="#5C6BC0" style="color:white">Créer une disponibilité</v-btn></v-card-title>
    <!-- <v-col>
      <v-select variant="outlined" hide-details v-model="gestionSelectedAgency" :items="agencyList" item-title="long"
        label="Choisir une ville" return-object></v-select>
    </v-col>
    <v-divider></v-divider> -->
    <!-- <v-col class="px-0" v-if="gestionSelectedAgency">
      AGENCE - {{ gestionSelectedAgency.PR_NOM_AG }} <br /> ADRESSE - {{ gestionSelectedAgency.PR_ADRESSE_AG }} {{
        gestionSelectedAgency.PR_VILLE_AG
      }} {{ gestionSelectedAgency.PR_CP_AG }}
    </v-col> -->
    <v-divider></v-divider>
    <v-col v-if="gestionSelectedAgency" style="background-color:#F5F5F5;">
      <v-row>
        <v-col cols="12" md="12" class="pb-0" style="background-color:#F5F5F5;">
          <v-card style="border:solid 1px lightgrey;" flat class="mb-2">
            <v-col>
              <v-row justify="center" align="center">
                <v-col cols="auto" class="pr-0" align="">
                  Disponibilité affiché :
                </v-col>
                <v-col align="left" class="pa-1 pl-2">
                  <v-btn-toggle v-model="selectedDateType" density="compact">
                    <v-btn :style="selectedDateType == 0 ? 'background-color:#8D6E63;color:white;' : ''"
                      density="compact" variant="flat" style="border: solid 1px lightgrey"> J ⇆
                      7</v-btn>
                    <v-btn :style="selectedDateType == 1 ? 'background-color:#8D6E63;color:white;' : ''"
                      density="compact" variant="flat" style="border: solid 1px lightgrey"> J ⇆
                      30</v-btn>
                    <v-btn :style="selectedDateType == 2 ? 'background-color:#8D6E63;color:white;' : ''"
                      density="compact" variant="flat" style="border: solid 1px lightgrey">
                      Personnalisée</v-btn> </v-btn-toggle>
                </v-col>
                <v-col align="right">
                  <v-btn @click="isExelOpen = true" variant="flat" style="border:solid 1px lightgrey">
                    Télécharger <v-icon> mdi-download </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" class="py-0" v-if="(selectedDateType == 2)">
                  <!-- {{ calendarPersoDate }} -->
                  <DatePicker :timePicker="false" v-model="calendarPersoDate" :format="'dd/MM/yyyy'"
                    :modelType="'timestamp'" locale="fr" :enableTimePicker="false" autoApply range>
                    <template #trigger>
                      <v-card class="pa-1" variant="outlined">
                        <v-icon color="#9E9E9E">mdi-calendar</v-icon>
                        {{ getdateFromated(parseInt(calendarPersoDate[0]), 'LL') }} - {{
                          getdateFromated(parseInt(calendarPersoDate[1]), 'LL')
                        }}
                      </v-card>
                    </template>
                  </DatePicker>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
          <v-text-field variant="outlined" style="background-color:white; width:100%" hide-details density="compact"
            class="mb-2" placeholder="Rechercher par code client" v-model="searchedClient">
            <v-btn density="compact" style="position:absolute;right:5px;color:white;" color="#7986CB"
              @click="searchByClient(searchedClient)">
              <!-- @click="tabTextFilter = searchedClient" -->
              Rechercher
            </v-btn>
          </v-text-field>
          <v-select label="Agence à afficher" variant="outlined" v-model="agencyToShow"
            style="background-color:white; width:100%" hide-details density="compact" :items="agencyList" multiple chips
            closable-chips return-object></v-select>
          <v-checkbox class="mb-2 mx-0 px-0" v-model="boxAgencyShowAll" label='Tout séléctionner / déséléctionner'
            hide-details density="compact"></v-checkbox>
          <EasyDataTable v-if="dispoList.length != 0" ref="dataTable" table-height="620" style="background-color:white"
            :headers="headers" :items="getTabItemsFiltered" border-cell :empty-message="'Aucun RDV prédéfinie'"
            :rows-per-page="nbrPagination" hide-footer>
            <template #item-client="item">
              <div v-if="item.libre == 'Non'">
                {{ item.NOM }}
                {{ item.PRENOM }} {{ item.CODE_CLIENT }}
              </div>
            </template>
            <template #item-date="item">
              {{ getdateFromated(item.date, 'll') }}
            </template>
            <template #item-libre="item">
              <div style="height:100%;width:100%;" align="center"
                :style="{ 'background-color': item.libre == 'Oui' ? '#5C6BC0' : '', 'color': item.libre == 'Oui' ? 'white' : 'black' }">
                {{ item.libre }}
              </div>
            </template>
            <template #item-HEBDOMADAIRE="item">
              <div v-if="item.HEBDOMADAIRE == 'Oui'" style="height:100%;width:100%;color:white;" align="center"
                :style="{ 'background-color': stringToColour(item.ID_HEBDOMADAIRE) }">
                {{ item.HEBDOMADAIRE }}
              </div>
            </template>
            <template #item-edit="item">
              <v-row>
                <div style="width:100%" align="center">
                  <v-icon color="green" @click="setModelData(item)">mdi-calendar-edit</v-icon>
                </div>
              </v-row>
            </template>
            <template #item-delete="item">
              <div style="width:100%" align="center">
                <v-icon color="red" @click="deleteDispo(item)">mdi-delete-forever</v-icon>
              </div>
            </template>
            <template #item-deleteRecurrence="item">
              <div>
                <div v-if="item.HEBDOMADAIRE == 'Oui'" style="width:100%" align="center">
                  <v-btn @click="deleteRecurrenceDispo(item)" :color="stringToColour(item.ID_HEBDOMADAIRE)" density="">
                    <v-icon color="white">mdi-delete-forever</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>

          </EasyDataTable>
          <!-- <v-pagination v-model="currentPaginationNumber" density="comfortable" class="" rounded="circle"
            :length="maxPaginationNumber"></v-pagination> -->

        </v-col>
        <!-- <v-divider vertical></v-divider> -->
      </v-row>
    </v-col>
    <!-- <v-divider></v-divider> -->
    <v-btn width="100%" variant="flat" color="#E57373" style="color: white;border-radius: 0px;" @click="close()">Fermer
    </v-btn>
  </v-card>
</template>

<script>
import moment from "moment";
import engine from "../core/dataApi";
import ModalEvent from "./model-event.vue";

export default {
  data() {
    return {
      exelIsDownloading: false,
      boxAgencyShowAll: true,
      agencyToShow: [],
      creatDispoIsOpen: false,
      isExelOpen: false,
      deleteFunction: undefined,
      tabTextFilter: undefined,
      searchedClient: undefined,
      selectedDateType: 0,
      selectedRdvType: undefined,
      gestionSelectedDayEnd: undefined,
      repetitionInterval: 1,
      currentPaginationNumber: 1,
      selectedDispoToDelete: undefined,
      isDeleteOpen: false,
      isDeleterecurrenceOpen: false,
      isHebdomadaireSelected: false,
      modalData: {},
      nbrPagination: 200,
      isModalDataOpen: false,
      // gestionSelectedStart: undefined,
      gestionSelectedTime: [],
      dispoList: [],
      gestionSelectedDay: undefined,
      gestionSelectedAgency: undefined,
      calendarStartDate: undefined,
      calendarEndDate: undefined,
      calendarPersoDate: undefined,
      // headers: [
      //   { text: "Jour", value: "date", sortable: true, width: 10 },
      //   { text: "Début", value: "debut", sortable: true, width: 10 },
      //   { text: "Fin", value: "fin", sortable: true, width: 10 },
      //   { text: "Type", value: "TYPE_ID", sortable: true, width: 10 },
      //   // { text: "Disponible", value: "libre", sortable: true, width: 10 },
      //   { text: "Numéro", value: "num", sortable: true, width: 10 },
      //   { text: "Client", value: "client", sortable: true, width: 10 },
      //   { text: "Téléphone", value: "NUMERO", sortable: true, width: 10 },
      //   // { text: "Commentaire", value: "COMMENTAIRE_formated", sortable: true, width: 10 },
      //   { text: "Type de vélo", value: "VELO_TYPE", sortable: true, width: 10 },
      //   { text: "Agence", value: "AGENCE", sortable: true, width: 10 },
      //   { text: "Récurrent", value: "HEBDOMADAIRE", sortable: true, width: 10 },
      //   { text: "Supprimer les récurrences", value: "deleteRecurrence", width: 10 },
      //   { text: "", value: "edit", width: 10 },
      //   { text: "", value: "delete", width: 10 }
      // ],
    };
  },
  computed: {
    headers() {
      let headers = [
        { text: "Jour", value: "date", sortable: true, width: 10 },
        { text: "Début", value: "debut", sortable: true, width: 10 },
        { text: "Fin", value: "fin", sortable: true, width: 10 },
        { text: "Type", value: "TYPE_ID", sortable: true, width: 10 },
        // { text: "Disponible", value: "libre", sortable: true, width: 10 },
        { text: "Numéro", value: "num", sortable: true, width: 10 },
        { text: "Client", value: "client", sortable: true, width: 10 },
        { text: "Téléphone", value: "NUMERO", sortable: true, width: 10 },
        // { text: "Commentaire", value: "COMMENTAIRE_formated", sortable: true, width: 10 },
        { text: "Type de vélo", value: "VELO_TYPE", sortable: true, width: 10 },
        { text: "Agence", value: "AGENCE", sortable: true, width: 10 },
        // { text: "Récurrent", value: "HEBDOMADAIRE", sortable: true, width: 10 },
        // { text: "Supprimer les récurrences", value: "deleteRecurrence", width: 10 },
        // { text: "", value: "edit", width: 10 },
        // { text: "", value: "delete", width: 10 }
      ]

      if (this.isAdmin) {
        headers.push({ text: "Récurrent", value: "HEBDOMADAIRE", sortable: true, width: 10 })
        headers.push({ text: "Supprimer les récurrences", value: "deleteRecurrence", width: 10 })
        headers.push({ text: "", value: "edit", width: 10 })
        headers.push({ text: "", value: "delete", width: 10 })
      }

      return headers
    },
    getExelElemToShow() {
      return [
        {
          key: 'date', label: 'Jour numérique'
        },
        { key: 'day', label: 'Jour' },
        { key: 'debut', label: 'Début' },
        { key: 'fin', label: 'Fin' },
        { key: 'TYPE_ID', label: 'Type' },
        { label: "Téléphone", key: "NUMERO" },
        { label: "Commentaire", key: "COMMENTAIRE_formated" },
        { label: "Type de vélo", key: "VELO_TYPE" },
        { key: 'num', label: 'Numéro de réservation' },
        { key: 'client', label: 'Client' },
        { key: 'AGENCE', label: 'Agence' },
      ]
    },
    getExelElemToDownload() {
      return [
        { key: 'day', label: 'Jour' },
        { key: 'debut', label: 'Début' },
        { key: 'fin', label: 'Fin' },
        { key: 'TYPE_ID', label: 'Type' },
        { label: "Téléphone", key: "NUMERO" },
        { label: "Commentaire", key: "COMMENTAIRE_formated" },
        { label: "Type de vélo", key: "VELO_TYPE" },
        { key: 'num', label: 'Numéro de réservation' },
        { key: 'client', label: 'Client' },
        { key: 'AGENCE', label: 'Agence' },
      ]
    },
    getTabItemsFiltered() {
      let agencyToShowNameListe = this.agencyToShow.map((val) => val.PR_NOM_AG)
      let that = this
      let ret = []
      let lastRet = []

      if (that.agencyToShow.length > 0) {
        ret = this.dispoList.filter((val) => agencyToShowNameListe.includes(val.AGENCE))
        ret = ret.map((val) => that.setTabName(val))
        for (let i = 0; i < ret.length; i++) {
          let tmp = ret[i]
          let newCom = ""
          if (tmp['COMMENTAIRE'] && tmp['COMMENTAIRE'] != undefined && tmp['COMMENTAIRE'] != '' && tmp['COMMENTAIRE'] != '[]') {
            let tmpCom = JSON.parse(tmp['COMMENTAIRE'])
            for (let a = 0; a < tmpCom.length; a++) {
              newCom += tmpCom[a].msg + '/ '
            }
          }
          tmp['COMMENTAIRE_formated'] = newCom
          lastRet.push(tmp)
        }
        ret = lastRet
      }
      else
        ret = []
      return ret
    },
    // getTabItemsFilteredForExel() {
    //   let ret = this.getTabItemsFiltered
    //   let lastRet = []
    //   for (let i = 0; i < ret.length; i++) {
    //     let tmp = ret[i]
    //     let newCom = ""
    //     if (tmp['COMMENTAIRE'] && tmp['COMMENTAIRE'] != undefined && tmp['COMMENTAIRE'] != '' && tmp['COMMENTAIRE'] != '[]') {
    //       let tmpCom = JSON.parse(tmp['COMMENTAIRE'])
    //       for (let a = 0; a < tmpCom.length; a++) {
    //         newCom += tmpCom[a].date + ' ' + tmpCom[a].user + ' : ' + tmpCom[a].msg + ' / '
    //       }
    //     }
    //     tmp['COMMENTAIRE_formated'] = newCom
    //     newRet.push(tmp)
    //   }
    //   return newRet
    // },
    gestionSelectedTimeIsGood() {
      let timeVerif = this.gestionSelectedTime.find((val) => val.start == undefined || val.end == undefined)
      return timeVerif == undefined
    },
    getNbrWeek() {
      let start = moment(this.gestionSelectedDay)
      let end = moment(this.gestionSelectedDayEnd)
      return end.diff(start, 'week')
    },
    getDisabledWeekDays() {
      let day = moment(this.gestionSelectedDay).format('dd')
      let numKey = {
        "Su": 0,
        "Mo": 1,
        "Tu": 2,
        "We": 3,
        "Th": 4,
        "Fr": 5,
        "Sa": 6,
      }
      let disabledWeekDays = [0, 1, 2, 3, 4, 5, 6]
      disabledWeekDays.splice(numKey[day], 1)
      return disabledWeekDays
    },
    maxPaginationNumber() {
      return Math.ceil(
        this.dispoList.length / this.nbrPagination
      );
    },
  },
  components: {
    ModalEvent
  },
  props: {
    isAdmin: {},
    agencyList: {},
    rdvTypeListe: {}
  },
  watch: {
    boxAgencyShowAll(val) {
      if (val) {
        this.agencyToShow = this.agencyList

      } else {
        this.agencyToShow = []
      }
    },
    creatDispoIsOpen(val) {
      if (val == false) {
        // this.gestionSelectedAgency = undefined
        this.gestionSelectedDay = undefined
        this.gestionSelectedDayEnd = undefined
        this.gestionSelectedTime = [{ start: undefined, end: undefined }]
      }
    },
    searchedClient(val) {
      if (val == undefined || val.length == 0) {
        this.tabTextFilter = undefined
        this.updateDispoGestion()
      }
    },
    calendarPersoDate(val) {
      this.calendarStartDate = moment(val[0]).set({ 'hours': 0, 'minutes': 0, 'seconde': 0 }).format('x')
      this.calendarEndDate = moment(val[1]).set({ 'hours': 23, 'minutes': 59, 'seconde': 59 }).format('x')
      this.updateDispoGestion();
    },
    selectedDateType(val) {
      if (val == 0) {
        this.calendarStartDate = moment().subtract('days', 7).set({ 'hours': 0, 'minutes': 0, 'seconde': '0' }).format('x')
        this.calendarEndDate = moment().add('days', 7).set({ 'hours': 23, 'minutes': 59, 'seconde': '59' }).format('x')
      }
      if (val == 1) {
        this.calendarStartDate = moment().subtract('days', 30).set({ 'hours': 0, 'minutes': 0, 'seconde': '0' }).format('x')
        this.calendarEndDate = moment().add('days', 30).set({ 'hours': 23, 'minutes': 59, 'seconde': 59 }).format('x')
      }
      if (val == 2) {
        this.calendarPersoDate = [this.calendarStartDate, this.calendarEndDate]
      }
      this.updateDispoGestion();
    },
    currentPaginationNumber(val) {
      this.$refs["dataTable"].updatePage(val);
    },
    // gestionSelectedAgency(val) {
    //   if (val != undefined) {
    //     this.updateDispoGestion();
    //   }
    // },
  },
  mounted() {
    this.calendarStartDate = moment().subtract('days', 7).set({ 'hours': 0, 'minutes': 0, 'seconde': '0' }).format('x')
    this.calendarEndDate = moment().add('days', 7).set({ 'hours': 0, 'minutes': 0, 'seconde': '0' }).format('x')
    this.gestionSelectedTime = [{
      "start": { "hours": moment().format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 },
      "end": { "hours": moment().add(1, 'h').format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 }
    }]
    this.gestionSelectedAgency = this.agencyList[0];
    // this.selectedRdvType = this.rdvTypeListe[0].KEY
    this.selectedRdvType = this.rdvTypeListe
    this.agencyToShow = this.agencyList
    this.updateDispoGestion()
  },
  methods: {
    roundMinute(minutes) {
      return Math.round(minutes / 10) * 10
    },
    searchByClient(client) {
      this.tabTextFilter = client
      if (this.gestionSelectedAgency != undefined) {
        this.dispoList = [];
        let that = this;
        engine.get_dispo_by_client(
          client,
          function (data) {
            if (data.code == 0) {
              that.setDispo(data)
            }
            that.isModalDataOpen = false
          }
        );
      }
    },
    setTabName(val) {
      let id = ""
      if (val['libre'] == 'Oui' || val['libre'] == 'Non')
        return val

      val['debut'] = val.start.hours + 'h' + val.start.minutes
      val['fin'] = val.end.hours + 'h' + val.end.minutes
      let TabId = Object.values(val.TYPE_ID)
      for (let i = 0; i < TabId.length; i++) {
        let tmpId = this.rdvTypeListe.find((val) => parseInt(val.KEY) == parseInt(TabId[i]))
        if (tmpId)
          id += tmpId.label + ' / '
      }
      val['TYPE_ID'] = id
      val['client'] = val.libre == 0 ? val.NOM + " " + val.PRENOM : ''
      val['libre'] = val.libre == true ? 'Oui' : "Non"
      val['HEBDOMADAIRE'] = val.HEBDOMADAIRE == true ? 'Oui' : "Non"

      return val
    },
    exportAsExcel() {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'export'
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
    },
    openDeleteModal(fnct) {
      this.deleteFunction = fnct
      this.isDeleteOpen = true
    },
    removeGestionTimeCard(idx) {
      this.gestionSelectedTime.splice(idx, 1)
    },
    addGestionTimeCard() {
      this.gestionSelectedTime.push(
        {
          "start": { "hours": moment().format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 },
          "end": { "hours": moment().add(1, 'h').format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 }
        }
      )
    },
    stringToColour(str) {
      if (str == undefined)
        return '#5C6BC0'
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var a = 0; a < 3; a++) {
        var value = (hash >> (a * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    getdateFromTimestamp(dateTimestamp) {
      return moment(dateTimestamp).format()
    },
    getdateFromated(date, format) {
      return moment(date).lang('fr').format(format)
    },
    setModelData(tmpItem) {
      let item = JSON.parse(JSON.stringify(tmpItem))
      let tmp = {
        agence: [this.gestionSelectedAgency.PR_VILLE_AG],
        dates: { start: undefined, end: undefined },
        info: { extendedProps: { data: {} } }
      }
      tmp.dates.start = item.start.hours + ':' + item.start.minutes
      tmp.dates.end = item.end.hours + ':' + item.end.minutes
      tmp.info['extendedProps']['data'] = JSON.parse(JSON.stringify(this.dispoList.find((val) => val['Clé primaire'] == item['Clé primaire'])))
      // tmp.info['extendedProps']['data']['RESERVATION_NUM'] = item.num
      // tmp.info['extendedProps']['data']['COMMENTAIRE'] = item.COMMENTAIRE
      // tmp.info['extendedProps']['data']['RDV_VALIDE'] = item.RDV_VALIDE
      // tmp.info['extendedProps']['data']['Clé primaire'] = item['Clé primaire']
      // tmp.info['extendedProps']['data']['LIBRE'] = item['LIBRE']
      // tmp.info['extendedProps']['data']['TYPE_ID'] = item['TYPE_ID']

      let findedIdListe = []

      let typeIdListe = JSON.parse(JSON.stringify(item['TYPE_ID'])).split(' / ')
      for (let i = 0; i < typeIdListe.length; i++) {
        let idx = this.rdvTypeListe.findIndex((val) => val.label == typeIdListe[i])
        if (idx != -1 && this.rdvTypeListe[idx].KEY) {
          findedIdListe.push(this.rdvTypeListe[idx].KEY)
        }
      }
      tmp.info['extendedProps']['data']['TYPE_ID'] = findedIdListe

      this.modalData = JSON.parse(JSON.stringify(tmp))
      this.isModalDataOpen = true
    },
    close() {
      this.$emit("closeModal");
    },
    deleteDispo(dispo) {
      this.selectedDispoToDelete = dispo
      this.openDeleteModal(this.valideDelete)
    },
    valideDelete() {
      let that = this;
      engine.delete_dispo(this.selectedDispoToDelete["Clé primaire"], function () {
        that.updateDispoGestion();
        that.isDeleteOpen = false
      });
    },
    cancelDelete() {
      this.selectedDispoToDelete = undefined
      this.isDeleteOpen = false
    },
    deleteRecurrenceDispo(dispo) {
      this.selectedDispoToDelete = dispo
      this.isDeleterecurrenceOpen = true
    },
    valideDeleteFutureRecurrence() {
      let that = this;
      engine.delete_future_dispo_recurrence(this.selectedDispoToDelete["ID_HEBDOMADAIRE"], this.selectedDispoToDelete.date, function () {
        that.updateDispoGestion();
        that.isDeleterecurrenceOpen = false
      });
    },
    valideDeleteRecurrence() {
      let that = this;
      engine.delete_dispo_recurrence(this.selectedDispoToDelete["ID_HEBDOMADAIRE"], function () {
        that.updateDispoGestion();
        that.isDeleterecurrenceOpen = false
      });
    },
    cancelDeleteRecurrence() {
      this.selectedDispoToDelete = undefined
      this.isDeleterecurrenceOpen = false
    },

    setRecurciveDispo(date, cntInterval, cnt, fmData) {
      let timestampDate = date.format('x')
      let that = this
      if (cntInterval == 0) {
        cntInterval = this.repetitionInterval
        fmData['JOUR'] = timestampDate
        engine.set_dispo(fmData, function () {
          if (cnt - 1 < 0) {
            that.updateDispoGestion();
          } else {
            date = date.add(7, 'd')
            cntInterval -= 1
            cnt -= 1
            that.setRecurciveDispo(date, cntInterval, cnt, fmData)
          }
        });
      } else {
        date = date.add(7, 'd')
        cntInterval -= 1
        cnt -= 1
        that.setRecurciveDispo(date, cntInterval, cnt, fmData)
      }
    },
    addDispo() {
      let that = this;

      for (let i = 0; i < this.gestionSelectedTime.length; i++) {
        let timeData = this.gestionSelectedTime[i]

        let startHours = timeData.start["hours"];
        let startMinutes = timeData.start["minutes"];
        let endHours = timeData.end["hours"];
        let endMinutes = timeData.end["minutes"];

        if (String(startHours).length == 1) startHours = "0" + startHours;
        if (String(startMinutes).length == 1) startMinutes = "0" + startMinutes;
        if (String(endHours).length == 1) endHours = "0" + endHours;
        if (String(endMinutes).length == 1) endMinutes = "0" + endMinutes;

        let fmData = {
          VILLE: this.gestionSelectedAgency.PR_VILLE_AG,
          AGENCE: this.gestionSelectedAgency.PR_NOM_AG,
          ADRESSE: this.gestionSelectedAgency.ADRESSE,
          AGENCE_MAP_LINK: this.gestionSelectedAgency.GPS,
          AGENCE_INDICATIONS: this.gestionSelectedAgency.INDICATIONS,
          DEBUT: startHours + ":" + startMinutes,
          FIN: endHours + ":" + endMinutes,
          JOUR: moment(this.gestionSelectedDay).set('hour', 12).format('x'),
          // JOUR: this.gestionSelectedDay,
          SUPPRIMER: 0,
          HEBDOMADAIRE: 0,
          LIBRE: 0,
          RDV_VALIDE: 0,
          COMMENTAIRE: "",
          RESERVATION_NUM: "",
          ID_HEBDOMADAIRE: "",
        };

        for (let a = 0; a < this.selectedRdvType.length; a++) {
          let key = 'TYPE_ID(' + (a + 1) + ')'
          fmData[key] = this.selectedRdvType[a].KEY
        }

        if (this.isHebdomadaireSelected == true) {
          fmData['HEBDOMADAIRE'] = 1
          fmData['ID_HEBDOMADAIRE'] = moment().format('x') + '_' + this.gestionSelectedDay + '_' + this.gestionSelectedDayEnd
          let cnt = this.getNbrWeek
          let cntInterval = 0
          let date = fmData['JOUR']

          that.setRecurciveDispo(moment.unix(parseInt(date) / 1000), cntInterval, cnt, fmData)
        } else {
          engine.set_dispo(fmData, function () {
            that.updateDispoGestion();
          });
        }
      }
      that.gestionSelectedTime = [{ start: undefined, end: undefined }];
      that.gestionSelectedDay = undefined;
      that.gestionSelectedDayEnd = undefined;
      that.isHebdomadaireSelected = false
      that.selectedRdvType = that.rdvTypeListe[0].KEY
      that.creatDispoIsOpen = false
    },
    setDispo(data) {
      let that = this;

      that.dispoList = []
      let tmpDispoList = []
      let dispo = data.data.data.map((val) => ({
        data: val.fieldData, date: {
          DEBUT: val.fieldData.DEBUT.split(":"),
          FIN: val.fieldData.FIN.split(":"),
        }
      }));
      for (let i = 0; i < dispo.length; i++) {
        let tmpData = dispo[i].data
        tmpData['day'] = moment(dispo[i].data.JOUR).lang("fr").format("ddd DD MMM YYYY")
        tmpData['num'] = dispo[i].data.RESERVATION_NUM
        tmpData['date'] = dispo[i].data.JOUR
        tmpData['libre'] = dispo[i].data.LIBRE == '0'
        tmpData['start'] = {
          hours: dispo[i].date.DEBUT[0],
          minutes: dispo[i].date.DEBUT[1],
          seconds: dispo[i].date.DEBUT[2],
        }
        tmpData['end'] = {
          hours: dispo[i].date.FIN[0],
          minutes: dispo[i].date.FIN[1],
          seconds: dispo[i].date.FIN[2],
        }
        // tmpData = {
        //   "Clé primaire": dispo[i].data["Clé primaire"],
        //   day: moment(dispo[i].data.JOUR).lang("fr").format("ddd DD MMM YYYY"),
        //   num: dispo[i].data.RESERVATION_NUM,
        //   date: dispo[i].data.JOUR,
        //   libre: dispo[i].data.LIBRE == '0',
        //   LIBRE: dispo[i].data.LIBRE,
        //   COMMENTAIRE: dispo[i].data.COMMENTAIRE,
        //   VELO_TYPE: dispo[i].data.VELO_TYPE,
        //   HEBDOMADAIRE: dispo[i].data.HEBDOMADAIRE,
        //   ID_HEBDOMADAIRE: dispo[i].data.ID_HEBDOMADAIRE,
        //   NOM: dispo[i].data.NOM,
        //   CODE_CLIENT: dispo[i].data.CODE_CLIENT,
        //   PRENOM: dispo[i].data.PRENOM,
        //   RDV_VALIDE: dispo[i].data.RDV_VALIDE,
        //   AGENCE: dispo[i].data.AGENCE,
        //   start: {
        //     hours: dispo[i].date.DEBUT[0],
        //     minutes: dispo[i].date.DEBUT[1],
        //     seconds: dispo[i].date.DEBUT[2],
        //   },
        //   end: {
        //     hours: dispo[i].date.FIN[0],
        //     minutes: dispo[i].date.FIN[1],
        //     seconds: dispo[i].date.FIN[2],
        //   },
        // }
        let type = []
        for (let a = 0; a < that.rdvTypeListe.length; a++) {
          let key = 'TYPE_ID(' + (a + 1) + ')'
          if (dispo[i].data[key] != '')
            type.push(dispo[i].data[key])
        }
        tmpData.TYPE_ID = [...new Set(type)]
        tmpDispoList.push(tmpData);
      }
      that.dispoList = tmpDispoList

    },
    updateDispoGestion() {
      if (this.gestionSelectedAgency != undefined) {
        this.dispoList = [];
        let that = this;
        engine.get_dispo_between_timestamp(
          this.calendarStartDate,
          this.calendarEndDate,
          function (data) {
            if (data.code == 0) {
              that.setDispo(data)
            }
            that.isModalDataOpen = false
          }
        );
      }
    },
  },
};
</script>

<style></style>