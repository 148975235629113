<template>
  <v-dialog v-model="verifisDoubleVerifSignOpen" width="98%" max-width="350px">
    <v-col>
      <v-card align="center" class="pa-4">
        <h2>
          Valider mon envoie
        </h2>
        <v-col>
          <v-row>
            <v-col>
              <v-btn color="#66BB6A" style="color:white" @click="sendDoubleVerifbyMail">
                Oui
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="red" style="color:white" @click="verifisDoubleVerifSignOpen = false">
                Non
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-dialog>
  <v-dialog v-model="isDoubleVerifSignOpen" width="98%" max-width="500px">
    <v-card>
      <v-col align=center>
        <v-icon @click="isDoubleVerifSignOpen = false" size="35" color="red"
          style="position: absolute; right: 0px; top:0px;z-index: 2;">
          mdi-close-circle</v-icon>
        <!-- <h3 class="mt-1">
          Envoie pour signature à double authentification
        </h3> -->
        <!-- <v-col>
          <div>
            Email : {{ fileIsSelectedData.email }}
          </div>
          <div>
            Téléphone : {{ fileIsSelectedData.phone }}
          </div>
        </v-col> -->
        <v-col>
          <v-card color="#F5F5F5" variant="flat" class="pa-2">
            <div class="my-4" style="font-size: 20px;" colspan="2">Information client</div>
            <table>
              <tbody>
                <tr>
                  <td>Email :</td>
                  <td style="font-weight: bold;">{{ fileIsSelectedData.email }}</td>
                </tr>
                <tr>
                  <td>Téléphone :</td>
                  <td style="font-weight: bold;">{{ fileIsSelectedData.phone }}</td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
        <v-col align="center">
          <v-btn color="#66BB6A" style="color:white" @click="verifisDoubleVerifSignOpen = true">
            Envoyer pour signature
          </v-btn>
        </v-col>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isEmailModalOpen" persistent>
    <div style="width:100%;position:relative" align="center">
      <v-card max-width="400" width="90%" class="pa-5">
        <v-icon @click="isEmailModalOpen = false" size="35" color="red" style="position: absolute; right: 0px; top:0px">
          mdi-close-circle</v-icon>
        <h3 class="mt-1">
          Envoie de mail(s)
        </h3>
        <v-text-field v-model="emailValue" label="Mail" hide-details class="pa-2">
        </v-text-field>
        <v-btn color="#66BB6A" @click="sendEmails">
          Envoyer
        </v-btn>
      </v-card>
    </div>
  </v-dialog>
  <v-dialog v-if="fileIsSelected" v-model="fileIsSelected" fullscreen persistent>
    <file-viewer @closeModal="closeModal" :fileIsSelectedData="fileIsSelectedData"></file-viewer>
  </v-dialog>
  <v-col class="px-1 py-0">
    <!-- {{ listeClient }} -->
    <v-card v-if="listeClient && listeClient.length > 0" class="pa-2" :style="{ 'height': windowHeight * 0.93 + 'px' }">
      <v-autocomplete hide-details bg-color="#ECEFF1" style="color:black" :items="listeClient" v-model="selectedClient"
        label="Séléctionner un client" item-title="SOCIETE" class="mb-2" return-object
        no-data-text="Aucun client trouvé" clearable></v-autocomplete>
      <div v-if="selectedClient">
        <v-select bg-color="#ECEFF1" hide-details class="pb-2" variant="outlined" :items="selectData"
          v-model="selectedFilesToShowType" multiple chips closable-chips></v-select>
        <Vue3EasyDataTable v-model:items-selected="selectedFiles" :headers="getHeader" @click-row="clickRow"
          table-class-name="customize-table" :items="getFilteredFiles" :table-height="windowHeight * 0.71"
          rows-per-page-message="Fichier par page" rows-of-page-separator-message="total" header-text-direction="center"
          body-text-direction="center" empty-message="Aucun document disponible">
          <template #item-isSigned="{ isSigned }">
            <div align="center">
              <div v-if="isSigned == 'OK'">
                <v-icon size="35" color="#66BB6A"> mdi-check-circle</v-icon>
              </div>
              <div v-else-if="isSigned == 'NOK'">
                <v-icon size="40" color="#FFB300"> mdi-weather-cloudy</v-icon>
              </div>
            </div>
          </template>
          <template #item-signeByMail="item">
            <div align="center">
              <div v-if="item.isSigned == 'OK'">
                <v-btn variant="text" icon="mdi-send-clock-outline" color="#66BB6A" disabled style="font-size: 23px;">
                </v-btn>
              </div>
              <div v-else-if="item.isSigned == 'NOK'">
                <v-btn variant="text" icon="mdi-send-clock-outline" color="#66BB6A" style="font-size: 23px;"
                  @click="sendToDoubleVerifSign(item)">
                </v-btn>
              </div>
            </div>
          </template>
          <!-- <template #item-selected="item">
            <div align="center" style="position: relative;">
              <v-row justify="center">
                <v-col align="center" class="box-in-tab">
                  <v-checkbox style="" hide-details v-model="selectedFiles" :value="item.path" label=""></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </template> -->
          <template #item-show="item">
            <div align="center" style="position: relative;">
              <v-row justify="center">
                <v-col align="center">
                  <v-icon @click="clickRow(item)" size="35">
                    mdi-folder-open-outline
                  </v-icon>
                </v-col>
              </v-row>
            </div>
          </template>
        </Vue3EasyDataTable>
        <v-btn style="position: absolute;right:50px;top:60px;z-index: 2;color:#F5F5F5" size="32" density="compact"
          icon="mdi-reload" @click="updateData" color="#78909C"></v-btn>
        <v-btn style="position: absolute;right:90px;top:60px;z-index: 2;color:#F5F5F5;font-size: 14px;" size="32"
          density="compact" icon="mdi-email" @click="openMails" color="#78909C"></v-btn>
      </div>
    </v-card>
    <v-col v-else style="width: 100vw; padding-top: 40vh;" align="center">
      <v-progress-circular size="120" indeterminate color="white">Chargement</v-progress-circular>
    </v-col>
  </v-col>
  <v-snackbar v-model="snackbar.value" :timeout="snackbar.timeout" :color="snackbar.color"
    style="color:white;font-weight:bold" align="center">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import engine from '../core/index'
import FileViewer from './file-viewer.vue'
const Buffer = require('buffer').Buffer

export default {
  data() {
    return {
      snackbar: {
        value: false,
        timeout: 3000,
        color: undefined,
        text: undefined,
      },
      clientInformation: [],
      emailValue: undefined,
      isEmailModalOpen: false,
      downloadIsSelected: true,
      selectedFiles: [],
      selectedClient: undefined,
      listeClient: [],
      fileIsSelected: false,
      fileIsSelectedData: undefined,
      isDoubleVerifSignOpen: undefined,
      verifisDoubleVerifSignOpen: false,
      filesListe: [],
      windowHeight: window.innerHeight,
      selectedFilesToShowType: ['NOK'],
      selectData: [
        { title: 'Document en attente de signature', value: 'NOK' },
        { title: 'Document signé', value: 'OK' },
      ],
      webAppsType: 0,
      headersMateriel: [
        { text: "Type de fichier", value: "type", sortable: true },
        { text: "Numéro", value: "resaNum", sortable: true },
        { text: "Client", value: "client", sortable: true },
        // { text: "Nom", value: "title", sortable: true },
        { text: "Dernière modification", value: "date", sortable: true },
        { text: "Signé", value: "isSigned", sortable: true },
        { text: "Signature en ligne", value: "signeByMail", sortable: true },
      ],
      headersVélo: [
        { text: "Type de fichier", value: "type", sortable: true },
        { text: "Numéro", value: "resaNum", sortable: true },
        { text: "Client", value: "client", sortable: true },
        { text: "Nom", value: "title", sortable: true },
        { text: "Dernière modification", value: "date", sortable: true },
        { text: "Signé", value: "isSigned", sortable: true },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.getClientDataListe()
  },
  watch: {
    selectedClient() {
      this.updateData()
      // let that = this
      // engine.get_client_files_by_code(val, function (data) {
      //   if (data.code == 0)
      //     that.setDataFiles(data.data)
      // })
    }
  },
  methods: {
    openMails() {
      this.emailValue = this.selectedClient.mail
      this.isEmailModalOpen = true
    },
    sendDoubleVerifbyMail() {
      let that = this
      let fileData = {}

      engine.get_file_from_path(this.fileIsSelectedData.path, function (data) {
        if (data.code == 0) {
          let b64 = "data:application/pdf;base64," + new Buffer.from(data.data.fileBinary).toString('base64');
          fileData['document'] = b64
          engine.send_double_verif_by_mail(that.fileIsSelectedData, fileData, function (data) {
            if (data.code == 0) {
              that.snackbar.text = 'Email envoyé !'
              that.snackbar.color = '#66BB6A'
              that.snackbar.value = true
              that.selectedFiles = []
            } else {
              that.snackbar.text = 'Erreur l\'émail  n\'a pas été envoyé !'
              that.snackbar.color = '#E57373'
              that.snackbar.value = true
            }
          })
        }
      })

      this.isDoubleVerifSignOpen = false
      this.verifisDoubleVerifSignOpen = false

    },
    sendEmails() {
      let that = this
      let fileData = {}
      that.isEmailModalOpen = false
      for (let i = 0; i < this.selectedFiles.length; i++) {
        engine.get_file_from_path(this.selectedFiles[i].path, function (data) {
          if (data.code == 0) {
            let b64 = "data:application/pdf;base64," + new Buffer.from(data.data.fileBinary).toString('base64');
            fileData[that.selectedFiles[i]] = b64
            if (Object.keys(fileData).length == that.selectedFiles.length) {
              engine.send_files_by_mail(that.emailValue, fileData, function (data) {
                if (data.code == 0) {
                  that.snackbar.text = 'Email envoyé !'
                  that.snackbar.color = '#66BB6A'
                  that.snackbar.value = true
                  that.selectedFiles = []
                } else {
                  that.snackbar.text = 'Erreur l\'émail  n\'a pas été envoyé !'
                  that.snackbar.color = '#E57373'
                  that.snackbar.value = true
                }
              })
            }
          }
        })
      }
    },

    sendToDoubleVerifSign(item) {
      this.fileIsSelectedData = item
      this.isDoubleVerifSignOpen = true
    },
    getClientDataListe() {
      let that = this

      engine.get_client_data_liste(function (data) {
        if (data.code == 0)
          that.clientInformation = data.data.data.map((val) => val.fieldData)
        that.getClientListe()
      })
    },
    updateData() {
      let that = this
      this.filesListe = []
      engine.get_client_files_by_code(this.selectedClient.id, function (data) {
        if (data.code == 0)
          that.setDataFiles(data.data)
      })
    },
    setDataFiles(filesListe) {
      let finalFilesListe = []
      let foldersToShow = ['CONTRAT', "FACTURE"]
      for (let i = 0; i < filesListe.length; i++) {
        if (filesListe[i]['.tag'] == 'file' && filesListe[i].name.includes('.pdf') && foldersToShow.filter((val) => filesListe[i].path_display.includes(val)).length > 0) {
          let path_display = filesListe[i].path_display.split('/')
          path_display = path_display.filter((val) => val != "")
          let name_data = filesListe[i].name.split('.pdf')[0].split('@@')
          let obj = {}
          if (path_display.length >= 6) {
            // accen materiel
            this.webAppsType = 0
            obj = {
              path: filesListe[i].path_display,
              resaNum: name_data[1],
              type: path_display[4],
              // name: filesListe[i].name,
              typeValue: name_data[0],
              title: name_data[1],
              date: name_data[2],
              isSigned: name_data[3],
              position: name_data[4].split('-'),
              client: name_data[5]
            }
            if (name_data[6]) {
              obj['phone'] = name_data[6]
            }
            if (name_data[7]) {
              obj['email'] = name_data[7]
            }
          }
          // if (path_display.length == 7) {
          //   // accen vélo
          //   this.webAppsType = 1
          //   obj = {
          //     path: filesListe[i].path_display,
          //     resaNum: name_data[0],
          //     type: path_display[5],
          //     // name: filesListe[i].name,
          //     typeValue: name_data[0],
          //     title: name_data[1],
          //     date: name_data[2],
          //     isSigned: name_data[3],
          //     position: name_data[4].split('-'),
          //     client: name_data[5]
          //   }
          // }
          finalFilesListe.push(obj)
        }
      }
      this.filesListe = finalFilesListe
    },
    getClientListe() {
      let that = this
      engine.get_client_liste(function (data) {
        if (data.code == 0) {
          that.listeClient = data.data

          let newListe = []

          for (let i = 0; i < that.listeClient.length; i++) {
            let client = that.listeClient[i]
            let clientId = that.clientInformation.find((val) => val.id == client)
            let clientCode = that.clientInformation.find((val) => val.CODE_CLIENT == client)

            console.log("clientInformation", that.clientInformation);
            console.log("client", client);
            console.log("clientId", clientId);
            if (clientId)
              newListe.push({ id: client, SOCIETE: clientId.SOCIETE })
            else if (clientCode) {
              let clientName = clientCode.SOCIETE
              if (clientCode.CODE_CLIENT != undefined)
                clientName = clientName + ' - ' + clientCode.CODE_CLIENT
              newListe.push({ id: client, SOCIETE: clientName, mail: clientCode['CONTACT_MAIL(1)'] })
            }
            else
              newListe.push({ id: client, SOCIETE: client })
          }

          that.listeClient = newListe

          console.log("listeClient", that.listeClient);

          let query = that.$route.query
          if (query.search) {
            that.selectedClient = that.listeClient.find((val) => val.id == query.search)
          }
          // this.search = query.search

          // listeClient" v-model="selectedClient"

        }
      })
    },
    closeModal() {
      // this.updateFileListe()
      this.fileIsSelected = false
      this.fileIsSelectedData = undefined
      this.updateData()
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    getColorByType() {
      return "#7986CB"
    },
    clickRow(item) {
      // if (this.downloadIsSelected == false) {
      this.fileIsSelected = true
      this.fileIsSelectedData = item
      // } else {
      //   if (this.selectedFiles.includes(item.path) == false) {
      //     this.selectedFiles.push(item.path)
      //   }
      //   else {
      //     let idx = this.selectedFiles.indexOf(item.path)
      //     if (idx != undefined)
      //       this.selectedFiles.splice(idx, 1)
      //   }
      // }
    },
    // updateFileListe() {
    //   let that = this
    //   let folderNotSigne = "SIGNE_NOK"
    //   let folderSigne = "SIGNE_OK"
    //   engine.get_files_liste_from_path(folderNotSigne, function (data) {
    //     let tmpFileListe = []
    //     if (data.code == 0) {
    //       let files = data.data
    //       for (let i = 0; i < files.length; i++) {
    //         let fileInfo = files[i].split(".")[0].split('|')
    //         tmpFileListe.push({
    //           type: fileInfo[0],
    //           name: fileInfo[1],
    //           content: fileInfo[2],
    //           dateTimestamp: parseInt(moment(fileInfo[3]).format('x')),
    //           dateFormated: moment(fileInfo[3]).format('DD/MM/YYYY'),
    //           signState: 0,
    //           folderPath: folderNotSigne,
    //           fileName: files[i],
    //           color: that.getColorByType(fileInfo[0])
    //         })
    //       }
    //     }
    //     engine.get_files_liste_from_path(folderSigne, function (data) {
    //       if (data.code == 0) {
    //         let files = data.data
    //         for (let i = 0; i < files.length; i++) {
    //           let fileInfo = files[i].split(".")[0].split('|')
    //           tmpFileListe.push({
    //             type: fileInfo[0],
    //             name: fileInfo[1],
    //             content: fileInfo[2],
    //             dateTimestamp: parseInt(moment(fileInfo[3]).format('x')),
    //             dateFormated: moment(fileInfo[3]).format('DD/MM/YYYY'),
    //             signState: 1,
    //             folderPath: folderSigne,
    //             fileName: files[i],
    //             color: that.getColorByType(fileInfo[0])
    //           })
    //         }
    //       }
    //       that.filesListe = tmpFileListe
    //     })

    //   })
    // }
  },
  computed: {
    getHeader() {
      if (this.downloadIsSelected) {
        let finalheader = this.webAppsType == 0 ? this.headersMateriel : this.headersVélo
        // finalheader.push(
        // { text: "Visualiser", value: "show", sortable: true },
        // { text: "Sélectionner", value: "selected", sortable: true },
        // )
        return finalheader
      }
      return this.webAppsType == 0 ? this.headersMateriel : this.headersVélo
    },
    getFilteredFiles() {
      return this.filesListe.filter((val) => this.selectedFilesToShowType.includes(val.isSigned))
    }
  },
  components: {
    Vue3EasyDataTable,
    FileViewer
  }
}
</script>

<style>
.box-in-tab .v-input--horizontal {
  grid-template-areas: none;
  grid-template-columns: none;
  grid-template-rows: none;
}
</style>