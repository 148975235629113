<template>
    <div>
        <v-dialog v-model="isSavedModalOpen"
            v-if="$store.state.oRstoredData && $store.state.oRstoredData.length > 0 && isSavedModalOpen" width="99%"
            max-width="600">
            <v-card>
                <v-col align=center>
                    <h2>
                        Liste des ordres à synchroniser
                    </h2>
                </v-col>
                <v-divider></v-divider>
                <v-col align=center>
                    <v-table v-if="$store.state.oRstoredData && $store.state.oRstoredData.length > 0">
                        <thead>
                            <tr>
                                <th class="text-left" style="font-weight:bold">
                                    Numéro
                                </th>
                                <th class="text-left" style="font-weight:bold">
                                    Matricule
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in $store.state.oRstoredData" :key="idx">
                                <td>{{ item.data.finalObj['N° RAP COMP'] }}</td>
                                <td>{{ item.data.finalObj['N° MATR INT'] }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-col>
                <v-divider></v-divider>
                <v-btn color="#7986CB" style="color: white;border-radius: 0px;font-weight: bold;"
                    @click=sendSavedOrToServer>
                    Synchroniser</v-btn>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isOrVerificationOpen" width="auto">
            <v-card>
                <v-col style="font-weight: bold;">
                    Ne plus afficher <span style="color:red">{{ selectedOrData['N° RAP COMP'] }}</span> sur la
                    web-application ?
                </v-col>
                <v-col>
                    <v-row justify="center" align="center">
                        <v-col cols="6" align=center>
                            <v-btn color="#4CAF50" style="color:white" @click="valideSuppresion()">
                                Oui
                            </v-btn>
                        </v-col>
                        <v-col cols="6" align=center>
                            <v-btn color="#EF5350" style="color:white" @click="isOrVerificationOpen = false">
                                Non
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isOrModalOpen" fullscreen persistent>
            <v-card style="overflow: auto; max-height: 90vh;">
                <sav-form @close="closeModal()" :getOrListeAll="getOrListeAll" :itemsData="itemsData"
                    :technicienListe="technicienListe" :agenceListe="agenceListe" :clientListe="clientListe"
                    :checkListe="checkListe" :parcListe="parcListe" :stockListe="stockListe" :FormData="savForm"
                    :optionsObj="optionsObj" :user="getUserInformation"></sav-form>
            </v-card>
        </v-dialog>
        <v-card class="pa-2 mx-1">
            <v-col class="pb-2">
                <v-col>
                    <v-row align="center">
                        <v-col cols="auto">
                            <h3>Liste des interventions</h3>
                        </v-col>
                        <v-col cols="" v-if="$store.state.oRstoredData && $store.state.oRstoredData.length > 0">
                            <v-btn size="35" class="ml-2" @click="isSavedModalOpen = true" density="compact"
                                style="color:#FFCC80; background-color: #F44336; border-radius: 100%;border:solid 1px orange">
                                <v-icon size="25px">
                                    mdi-alert
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-row align="center" justify="center">
                    <v-col class="" style="text-transform: uppercase;">
                        <v-select bg-color="#ECEFF1" class="mt-2" label="Technicien(s)" variant="outlined"
                            closable-chips chips :items="techListe" multiple v-model="selectedTech"
                            hide-details></v-select>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn variant="flat" color="rgb(92, 107, 192)" style="color:white"
                            @click="openDialog(undefined)">Créer un
                            OR
                        </v-btn>
                        <v-btn icon="mdi-refresh" density="compact" style="font-size:20px" variant="flat" size="40"
                            @click="getOrListeAll" class="ml-3"></v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
                <v-text-field bg-color="#ECEFF1" variant="outlined" hide-details v-model="search" label="Recherche"
                    clearable></v-text-field>
            </v-col>
            <v-col class="py-1">
                <v-select variant="outlined" bg-color="#ECEFF1" label="Filtrer sur les OR..." multiple hide-details
                    v-model="orFilterByStatut" :items="listeStatutToFilter" closable-chips chips></v-select>
            </v-col>
            <v-col class="pt-1">
                <Vue3EasyDataTable :sort-by="'date_debut_location_TIMESTAMP'" :sort-type="'desc'"
                    table-class-name="customize-table" ref="dataTable" :headers="get_tab_header"
                    :items="get_or_liste_filtered" style="overflow: auto" :table-height="windowHeight * 0.55"
                    rows-per-page-message="Ligne par colonne" rows-of-page-separator-message="total"
                    empty-message="Aucune intervention trouvé" @click-row="showOr">
                    <template #item-Status_devis="item">
                        <div align=center class="pa-1">
                            <v-chip v-if="item['Status devis'] == 'Signé'" color="white"
                                style="font-weight: bold; background-color: green;">
                                {{ item['Status devis'] }}
                            </v-chip>
                            <v-chip v-else-if="item['Status devis'] == 'en cours'" color="white"
                                style="font-weight: bold; background-color: #FFA726;">
                                {{ item['Status devis'] }}
                            </v-chip>
                            <v-chip v-else style="font-weight: bold;">
                                {{ item['Status devis'] }}
                            </v-chip>
                        </div>
                    </template>
                    <template #item-DATE-RAPPORT="item">
                        <v-col>
                            {{ formatDate(item['DATE-RAPPORT'], 'DD/MM/YYYY') }}
                        </v-col>
                    </template>
                    <template #item-date_debut_location_TIMESTAMP="item">
                        <v-col v-if="item.date_debut_location_TIMESTAMP" align=center>
                            {{ formatDate(parseInt(item.date_debut_location_TIMESTAMP), 'DD/MM/YYYY') }}
                        </v-col>
                    </template>
                    <template #item-date_fin_location_TIMESTAMP="item">
                        <v-col v-if="item.date_fin_location_TIMESTAMP" align=center>
                            {{ formatDate(parseInt(item.date_fin_location_TIMESTAMP), 'DD/MM/YYYY') }}
                        </v-col>
                    </template>
                    <template #item-delete="item">
                        <div class="customize-header" align=center>
                            <v-row justify="center" align=center>
                                <v-col>
                                    <v-btn variant="flat" color="#E57373" style="font-size: 14px;z-index:5"
                                        @click="deleteOr(item)">
                                        <v-icon color="white">mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <!-- <template #item-show="item">
                        <div class="pa-1" style="">
                            <v-btn icon="mdi-open-in-app" size="35"
                                style="border-radius: 0px;font-size: 15px; width: 100%;color:white" color="#7986CB"
                                @click="showOr(item)">
                            </v-btn>
                        </div>
                    </template> -->
                    <template #item-Statut="item">
                        <v-col class="pa-4" style="">
                            <!-- {{ item.Table }} -->
                            <v-row align="center">
                                <v-col class="pa-1">
                                    <div v-if="item.Statut == 140">
                                        <v-card color="#EF9A9A" class="px-4 py-1" align=center variant="flat"
                                            style="font-weight: bold; color: white; border: solid 1px grey;">
                                            Cloturé
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-card :color="item.retour == 'oui' ? '#006064' : '#4CAF50'" class="px-4 py-1"
                                            align=center variant="flat"
                                            style="font-weight: bold; color: white; border: solid 1px grey;">
                                            En cours <span v-if="item.retour == 'oui'"> RETOUR </span>
                                        </v-card>
                                    </div>
                                </v-col>
                                <v-col class="pa-0" align=center
                                    v-if="$store.state && $store.state.oRstoredData && $store.state.oRstoredData.find((val) => (val.data.finalObj['id'] == item['id']))">
                                    <v-icon class="px-3" size="35px" color="red">
                                        mdi-cloud-outline
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                    </template>
                </Vue3EasyDataTable>
            </v-col>
        </v-card>
    </div>
    <v-snackbar v-model="snackbarData.value" :timeout="2000" :color="snackbarData.color">
        <div style="font-weight: bold;">
            {{ snackbarData.text }}
        </div>
    </v-snackbar>

</template>

<script>

import savHooks from '../hooks/sav'
import SavForm from './sav-form.vue'
import Vue3EasyDataTable from "vue3-easy-data-table";
import engine from '../core/DataApi'
import moment from 'moment'

export default {
    setup() {
        const { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListeAll, getUserInformation, checkListe, tableHeader, techListe } = savHooks()
        return { clientListe, parcListe, stockListe, savForm, orListe, technicienListe, agenceListe, getOrListeAll, getUserInformation, checkListe, tableHeader, techListe };
    },
    data() {
        return {
            snackbarData: {},
            isSavedModalOpen: false,
            selectedTech: [],
            orFilterByStatut: ['130'],
            listeStatutToFilter: [
                { title: 'Cloturé', value: '140' },
                { title: 'En cours', value: '130' },
            ],
            optionsObj: undefined,
            selectedOrData: undefined,
            isOrVerificationOpen: false,
            itemsData: undefined,
            search: "",
            windowHeight: window.innerHeight,
            isOrModalOpen: false
        }
    },
    mounted() {

        let query = this.$route.query
        if (query.search)
            this.search = query.search


        this.onResize()
        this.getOrOptions()
        this.selectedTech = [this.getUserInformation.user_name]
    },
    computed: {
        get_tab_header() {
            let tab_header = this.tableHeader

            // let listFromServ = this.tableHeader
            let list = [
                // {
                //     key: 'DATE RAPPORT',
                //     label: 'Date du rapport'
                // }, 
                //  {
                //     key: 'N° RAP COMP',
                //     label: 'Numéro'
                // }, {
                //     key: 'societeliv',
                //     label: 'Société'
                // }, {
                //     key: 'N° MATR INT',
                //     label: 'Matricule'
                // }, {
                //     key: 'MARQUE MACHINE',
                //     label: 'Marque'
                // }, {
                //     key: 'villeliv',
                //     label: 'Ville'
                // }, 
                // {
                //     key: 'show',
                //     label: 'Visualiser'
                // },
                {
                    key: 'Statut',
                    label: 'Statut',
                    index: -9999999
                },
                {
                    key: 'delete',
                    label: '',
                    index: 9999999
                }
            ];

            for (let i = 0; i < list.length; i++) {
                tab_header.push({
                    text: list[i].label,
                    value: list[i].key,
                    index: list[i].index,
                    // sortable: true,
                });
            }

            for (let i = 0; i < tab_header.length; i++) tab_header[i]['sortable'] = true

            tab_header = tab_header.sort((a, b) => a.index - b.index);
            return tab_header;
        },
        get_or_liste_filtered() {
            let liste = []

            if (this.search == undefined || this.search.length == 0) {
                liste = this.orListe

                for (let i = 0; i < this.orListe.length; i++)
                    liste[i]['DATE-RAPPORT'] = this.orListe[i]['DATE RAPPORT']
            }
            else {
                for (let i = 0; i < this.orListe.length; i++) {
                    let inter = this.orListe[i]
                    inter['DATE-RAPPORT'] = inter['DATE RAPPORT']
                    let key = inter['N° RAP COMP'] + inter['N° MATR INT'] + inter['societeliv'] + inter['num_contrat'] + inter['societeliv']

                    if (key.toUpperCase().includes(this.search.toUpperCase()))
                        liste.push(inter)
                }
            }


            if (this.selectedTech && this.selectedTech.length > 0)
                liste = liste.filter((val) => this.selectedTech.includes(val["Techniciens_Nom(1)"]))
            if (this.orFilterByStatut && this.orFilterByStatut.length > 0)
                liste = liste.filter((val) => this.orFilterByStatut.includes(val.Statut))
            
            liste = liste.sort((a, b) => { return (moment(b.CREATION).format("x") - moment(a.CREATION).format("x")) })

            return liste
        }
    },
    methods: {
        sendSavedOrToServer() {
            for (let i = 0; i < this.$store.state.oRstoredData.length; i++) {
                let ordre = this.$store.state.oRstoredData[i]
                let functionName = ordre.from

                if (functionName == "creatOrSAV_FICHE") {
                    console.log("creatOrSAV_FICHE", ordre);
                    this[functionName](ordre.data.finalObj, ordre.data.itemsData, ordre.data.items, ordre.data.form, ordre.data.signed)
                }
                if (functionName == "editOrSAV_FICHE") {
                    console.log("editOrSAV_FICHE", ordre);
                    this[functionName](ordre.data.finalObj, ordre.data.itemsData, ordre.data.items, ordre.data.form,)
                }
                if (functionName == "creatOrSAV2") {
                    console.log("creatOrSAV2", ordre);
                    this[functionName](ordre.data.finalObj, ordre.data.itemsData, ordre.data.items, ordre.data.form, ordre.data.optionsObj)
                }
            }
        },
        creatOrSAV_FICHE(finalObj, itemsData, items, form, signed) {
            let that = this

            engine.creat_new_or_1(finalObj, function (data) {
                if (data.code == 0) {
                    console.log("OR1 CREATED");

                    console.log("itemsData", itemsData);
                    let index = that.$store.state.oRstoredData.findIndex((val) => val.data.finalObj.id == finalObj.id)
                    console.log("index", index);
                    if (index > -1) that.$store.state.oRstoredData.splice(index, 1);

                    that.updateDropboxPhotos(data.data.orNum, itemsData, form)
                    that.getOrListeAll()
                    if (items.length == 0) {
                        if (signed == false) {
                            that.closeModal()
                        } else {
                            that.creatOrSAV2(data.data.orNum, finalObj.id)
                        }
                    }
                    for (let i = 0; i < items.length; i++) {
                        let moteurInfo = items[i]
                        // let id

                        // if (moteurInfo && moteurInfo.id)
                        //     id = moteurInfo.id
                        // else id = uuid.v4()
                        // id: moteurInfo.id,

                        let elem = {
                            id: moteurInfo.id,
                            "_LINK SAV": finalObj['_LINK MOTEUR'],
                            "id_SAV": finalObj.id,
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            'IS_FROM_WEB': moteurInfo.IS_FROM_WEB,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            // ID_Societe: clientAgency.ID_Societe,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_1_line(elem, function () {
                            if (i + 1 == items.length) {
                                if (signed == false) {
                                    that.closeModal()
                                } else {
                                    that.creatOrSAV2(data.data.orNum, finalObj.id)
                                }
                            }
                        })
                    }
                } else {
                    console.log("OR1 ERROR");
                }
            })
        },
        creatOrSAV2(finalObj, itemsData, items, form, optionsObj) {
            let that = this
            engine.creat_new_or_2(finalObj, function (data) {
                if (data.code == 0) {
                    console.log("itemsData", itemsData);
                    console.log("store", that.$store.state.oRstoredData);
                    let index = that.$store.state.oRstoredData.findIndex((val) => val.data.finalObj.id == finalObj.id)
                    console.log("index", index);
                    if (index > -1) that.$store.state.oRstoredData.splice(index, 1);

                    console.log("OR2 CREATED");
                    if (itemsData && itemsData.CARA_MATERIEL) {
                        let jsonInfo = JSON.parse(itemsData.CARA_MATERIEL)

                        if (optionsObj.auto_creat_etatdl && optionsObj.auto_creat_etatdl == 1 && jsonInfo && jsonInfo.NUM_CONTRAT) {
                            engine.show_etadl(jsonInfo.NUM_CONTRAT, form['equipement'], form.materiel.MATRICULE, itemsData.CARA_MATERIEL,
                                optionsObj.show_equipement_in_etadl == 1, function () { })
                        }
                    }
                    that.getOrListeAll()
                    for (let i = 0; i < items.length; i++) {
                        let moteurInfo = items[i]

                        let elem = {
                            id: moteurInfo.id,
                            "_LINK SAV": finalObj['_LINK MOTEUR'],
                            "id_SAV": finalObj['id'],
                            "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                            "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                            "IS_FROM_WEB": moteurInfo.IS_FROM_WEB,
                            "SAV QTE PROD": moteurInfo.qte,
                            "SAV PV UNIT": undefined,
                            "PIECE_TOTAL": undefined,
                            "FLAG_DELETE": undefined,
                            Index_Ligne: i + 1,
                        }
                        engine.creat_new_moteur_2_line(elem, function () {
                        })
                    }
                } else {
                    console.log("OR2 ERROR");
                }
            })
        },
        editOrSAV_FICHE(finalObj, itemsData, items, form) {
            let that = this

            engine.edit_or(finalObj, function (data) {
                if (data.code == 0) {
                    console.log("EDITED");
                    let index = that.$store.state.oRstoredData.findIndex((val) => val.data.finalObj.id == finalObj.id)
                    if (index > -1) that.$store.state.oRstoredData.splice(index, 1);

                    that.updateDropboxPhotos(data.data.orNum, itemsData, form)
                    engine.delete_old_moteur_by_link(finalObj['_LINK MOTEUR'], function () {
                        that.getOrListeAll()
                        if (items.length == 0)
                            that.$emit('close')
                        for (let i = 0; i < items.length; i++) {
                            let moteurInfo = items[i]
                            if (moteurInfo['IS_FROM_WEB'] == 'OUI' || moteurInfo['IS_FROM_WEB'] == '') {
                                let elem = {
                                    id: moteurInfo.id,
                                    // id: uuid.v4(),
                                    "_LINK SAV": finalObj['_LINK MOTEUR'],
                                    "id_SAV": itemsData.id,
                                    "SAV REF PRODUIT": moteurInfo.PIECE_REFERENCE,
                                    "SAV DESIGNATION": moteurInfo.PIECE_DESIGNATION,
                                    "SAV QTE PROD": moteurInfo.qte,
                                    "SAV PV UNIT": undefined,
                                    "PIECE_TOTAL": undefined,
                                    "FLAG_DELETE": undefined,
                                    "IS_FROM_WEB": 'OUI',
                                    // ID_Societe: clientAgency.ID_Societe,
                                    Index_Ligne: i + 1,
                                }
                                engine.creat_new_moteur_1_line(elem, function () {
                                    if (i + 1 == items.length) {
                                        that.$emit('close')
                                    }
                                })
                            }
                        }
                    })
                } else {
                    console.log("EDIT ERROR");
                }
            })
        },

        updateDropboxPhotos(num, itemsData, form) {
            let that = this
            let orNum
            if (num)
                orNum = num
            else
                orNum = itemsData['N° RAP COMP']
            engine.photo_folder_liste(orNum, function (data) {
                if (data.code == 0 && (data.data && data.data.length > 0)) {
                    let listeFileName = data.data
                    for (let i = 0; i < listeFileName.length; i++) {
                        engine.photo_delete(orNum, listeFileName[i], function () {
                            if (i + 1 == listeFileName.length)
                                that.updateDropboxPhotos()
                        })
                    }
                } else {
                    for (let i = 0; i < form['photos'].length; i++) {
                        engine.file_upload(form['photos'][i], orNum, ((i + 1) + " - " + moment().format('DD-MM-YYYY hh.mm.ss')), function () {
                        })
                    }
                }
            })

        },
        getOrOptions() {
            let that = this
            engine.get_or_options(function (data) {
                if (data.code == 0) {
                    let tmpTab = data.data.data.map((val) => val.fieldData)
                    that.optionsObj = {}
                    for (let i = 0; i < tmpTab.length; i++) {
                        that.optionsObj[tmpTab[i].Name] = tmpTab[i].Value
                        if (tmpTab[i].Value == 'true')
                            that.optionsObj[tmpTab[i].Name] = true
                        if (tmpTab[i].Value == 'false')
                            that.optionsObj[tmpTab[i].Name] = false
                    }
                }
            })
        },
        valideSuppresion() {
            let that = this
            if (this.selectedOrData.Table == "SAV_FICHE") {
                engine.delete_or_by_id(this.selectedOrData["N° RAP COMP"], function () {
                    that.getOrListeAll()
                    that.selectedOrData = undefined
                })
            } else {
                engine.delete_or2_by_id(this.selectedOrData["N° RAP COMP"], function () {
                    that.getOrListeAll()
                    that.selectedOrData = undefined
                })
            }
            this.isOrVerificationOpen = false
        },
        showOr(orData) {
            if (this.$store.state && this.$store.state.oRstoredData && this.$store.state.oRstoredData.find((val) => (val.data.finalObj['N° MATR INT'] == orData['N° MATR INT'] && val.data.finalObj['CLD_NUM_CONTRAT'] == orData['CLD_NUM_CONTRAT']))) {
                this.snackbarData = {
                    value: true,
                    text: "L'orde est en attente de synchronisation et n'est plus modifiable.",
                    color: 'red'
                }
            } else {
                if (this.isOrVerificationOpen == false)
                    this.openDialog(orData)
            }
        },
        deleteOr(orData) {
            this.isOrVerificationOpen = true
            this.selectedOrData = orData
        },
        closeModal() {
            this.isOrModalOpen = false
            // this.getOrListeAll()
        },
        onResize() {
            this.windowHeight = window.innerHeight
        },
        openDialog(data) {
            this.itemsData = data
            this.isOrModalOpen = true
        },
        formatDate(date, form) {
            let tmpDate = moment(date).format(form)
            if (tmpDate == 'Invalid date')
                tmpDate = undefined
            return tmpDate
        },
    },
    components: {
        SavForm,
        Vue3EasyDataTable
    }
}
</script>

<style>
.customize-table {
    --easy-table-border: 1px solid lightgrey;
    --easy-table-row-border: 1px solid lightgrey;

    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: black;
    --easy-table-header-background-color: #ECEFF1;

    /* --easy-table-header-item-padding: 10px 15px; */

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: black;
    --easy-table-body-row-background-color: #ECEFF1;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 12px;

    --easy-table-body-row-hover-font-color: black;
    --easy-table-body-row-hover-background-color: lightgray;

    /* --easy-table-body-item-padding: 10px 15px; */

    --easy-table-footer-background-color: #ECEFF1;
    --easy-table-footer-font-color: black;
    --easy-table-footer-font-size: 14px;
    --easy-table-footer-padding: 0px 10px;
    --easy-table-footer-height: 50px;

    --easy-table-rows-per-page-selector-width: 70px;
    --easy-table-rows-per-page-selector-option-padding: 10px;
    --easy-table-rows-per-page-selector-z-index: 1;


    /* --easy-table-scrollbar-track-color: #ECEFF1;
    --easy-table-scrollbar-color: #ECEFF1;
    --easy-table-scrollbar-thumb-color: #4c5d7a;
    --easy-table-scrollbar-corner-color: #ECEFF1; */

    --easy-table-loading-mask-background-color: #ECEFF1;
}
</style>