<template>
    <v-card>
        <v-col cols="12" align="center"
            style="font-weight: 700;text-transform: uppercase;border: solid 1px lightgrey;height:72.5vh; overflow:auto"
            class="pt-0 px-0">
            <h3 style="background-color:#5C6BC0;color:white" class="py-3">
                Ajout de disponibilité(s)
            </h3>
            <v-col>
                <v-card variant="flat" style="border:solid 1px lightgrey" class="pa-2">
                    Date sélectionné : {{ getdateFromated(selectedDate, 'DD MMMM YYYY') }}
                </v-card>
                <br>
                <v-card variant="flat" style="border:solid 1px lightgrey" class="pa-2">
                    Agence sélectionné : {{ modalData.info.WEB_LABEL }}
                </v-card>
                <v-col v-if="selectedDate" class="px-0 pb-0">
                    <v-card variant="flat" style="border:solid 1px lightgrey" class="pa-2">
                        Type de disponibilité
                        <v-select style="background-color:white;text-transform: none;" :items="rdvTypeListe"
                            item-title="label" item-value="KEY" variant="outlined" hide-details density="compact"
                            v-model="selectedRdvType" multiple chips closable-chips return-object="">
                        </v-select>
                    </v-card>
                </v-col>
            </v-col>
            <v-col class="pt-0" v-if="selectedDate && selectedRdvType.length != 0">
                <div align="center">
                    <v-card
                        :style="(gestionSelectedTime.length == 1) ? 'height:auto;border:solid 1px lightgrey' : 'height:300px;overflow:auto;border:solid 2px lightgrey'"
                        :class="(gestionSelectedTime.length == 1) ? '' : 'pa-2'" class="pa-2 mb-1" color="transparent"
                        variant="flat">
                        <v-card class="pa-2 mb-2" v-for="elem, idx in gestionSelectedTime.length" :key="idx"
                            color="#FAFAFA">
                            <v-btn v-if="(gestionSelectedTime.length > 1)" variant="text" density="compact"
                                style="position:absolute;right:10px;top:10px;cursor:pointer"
                                @click="removeGestionTimeCard(idx)">
                                <v-icon color="#EF5350" size="20">
                                    mdi-minus-circle</v-icon>
                            </v-btn>
                            <v-col>
                                Début disponibilité
                                <DatePicker :timePicker="true" v-model="gestionSelectedTime[idx].start"
                                    :textInput="true" autoApply
                                    :style="gestionSelectedTime[idx].start ? 'border:solid 2px green' : 'border:solid 2px red'">
                                </DatePicker>
                            </v-col>
                            <v-col>
                                Fin disponibilité
                                <DatePicker :timePicker="true" v-model="gestionSelectedTime[idx].end" :textInput="true"
                                    autoApply
                                    :style="gestionSelectedTime[idx].end ? 'border:solid 2px green' : 'border:solid 2px red'">
                                </DatePicker>
                            </v-col>
                        </v-card>
                    </v-card>
                    <v-btn variant="text" :icon="calendar" @click="addGestionTimeCard()"> <v-icon color="#43A047"
                            size="40">
                            mdi-plus-circle</v-icon> </v-btn>
                </div>
            </v-col>
            <v-btn v-if="(selectedDate && gestionSelectedTimeIsGood)" color="#66BB6A" class="mt-1 px-3"
                style="color: white; border-radius: 0px;width:80%" @click="addDispo()">Ajouter</v-btn>
        </v-col>
    </v-card>
</template>

<script>

import engine from "../core/dataApi";
import moment from 'moment'
export default {
    data() {
        return {
            selectedDate: undefined,
            selectedRdvType: [],
            gestionSelectedTime: [{ start: undefined, end: undefined }],
        }
    },
    props: {
        modalData: {},
        rdvTypeListe: {},
        updateDispo: {}
    },
    mounted() {
        this.selectedRdvType = this.rdvTypeListe
        this.selectedDate = this.modalData.dates

        this.gestionSelectedTime = [{
            "start": { "hours": moment().format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 },
            "end": { "hours": moment().add(1, 'h').format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 }
        }]
    },
    methods: {
        roundMinute(minutes) {
            return Math.round(minutes / 10) * 10
        },
        getdateFromated(date, format) {
            return moment(date).lang('fr').format(format)
        },
        addGestionTimeCard() {
            this.gestionSelectedTime.push(
                {
                    "start": { "hours": moment().format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 },
                    "end": { "hours": moment().add(1, 'h').format('HH'), "minutes": this.roundMinute(moment().format('mm')), "seconds": 0 }
                }
            )
        },
        removeGestionTimeCard(idx) {
            this.gestionSelectedTime.splice(idx, 1)
        },
        addDispo() {
            let that = this;

            for (let i = 0; i < this.gestionSelectedTime.length; i++) {
                let timeData = this.gestionSelectedTime[i]

                let startHours = timeData.start["hours"];
                let startMinutes = timeData.start["minutes"];
                let endHours = timeData.end["hours"];
                let endMinutes = timeData.end["minutes"];

                if (String(startHours).length == 1) startHours = "0" + startHours;
                if (String(startMinutes).length == 1) startMinutes = "0" + startMinutes;
                if (String(endHours).length == 1) endHours = "0" + endHours;
                if (String(endMinutes).length == 1) endMinutes = "0" + endMinutes;

                let fmData = {
                    VILLE: this.modalData.info.PR_VILLE_AG,
                    AGENCE: this.modalData.info.PR_NOM_AG,
                    ADRESSE: this.modalData.info.ADRESSE,
                    AGENCE_MAP_LINK: this.modalData.info.GPS,
                    AGENCE_INDICATIONS: this.modalData.info.INDICATIONS,
                    DEBUT: startHours + ":" + startMinutes,
                    FIN: endHours + ":" + endMinutes,
                    JOUR: moment(this.selectedDate).set({ 'h': 12 }).format('x'),
                    SUPPRIMER: 0,
                    HEBDOMADAIRE: 0,
                    LIBRE: 0,
                    RDV_VALIDE: 0,
                    COMMENTAIRE: "",
                    RESERVATION_NUM: "",
                    ID_HEBDOMADAIRE: "",
                };
                for (let a = 0; a < this.selectedRdvType.length; a++) {
                    let key = 'TYPE_ID(' + (a + 1) + ')'
                    fmData[key] = this.selectedRdvType[a].KEY
                }
                engine.set_dispo(fmData, function () {
                    that.updateDispo();
                    that.$emit('close')
                });
            }
        },
    }, computed: {
        gestionSelectedTimeIsGood() {
            let timeVerif = this.gestionSelectedTime.find((val) => val.start == undefined || val.end == undefined)
            return timeVerif == undefined
        },
    }
}
</script>

<style></style>