<template>
    <div v-if="showApp">
        <page-gestion-livraison></page-gestion-livraison>
    </div>
</template>

<script>
import PageGestionLivraison from "@/projects/gestion-livraison/components/page-home.vue";

export default {
    data() {
        return {
            showApp: false,
        };
    },
    mounted() {
        let appsToShow = this.$store.state.webApplicationToShow;
        let currentApp = this.$route.fullPath.replace("/", "").split('?')[0];
        if (appsToShow.includes(currentApp)) this.showApp = true;
    },
    components: {
        PageGestionLivraison,
    },
};
</script>

<style>

</style>