<template>
    <v-dialog v-model="isReplaceMaterielOpenVerif" max-width="300">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;" align=center>
                Valider ma modification
            </v-col>
            <v-col>
                <v-row justify="center" align="center">
                    <v-col cols="6" align="center">
                        <v-btn @click="function () {
                            isReplaceMaterielOpen = true
                            isReplaceMaterielOpenVerif = false
                        }" color="#F44336" style="color: white;">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-btn @click="changeMatricule" color="#4CAF50" style="color: white;">
                            Oui
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isReplaceMaterielOpen" max-width="500">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;">
                Sélectionner un nouveau matricule
            </v-col>
            <v-col>
                <v-autocomplete hide-details no-data-text="Aucun matricule trouvé" label="Matricule sélectionné"
                    v-model="selectedMatricule" :items="parcListe" item-value="MATRICULE" item-title="MATRICULE">
                </v-autocomplete>
            </v-col>
            <v-col class="pt-1" align="center">
                <v-btn color="#4CAF50" style="color: white;" @click="function () {
                    isReplaceMaterielOpen = false
                    isReplaceMaterielOpenVerif = true
                }">
                    Valider la modification
                </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
    <v-col style="max-height: 800px; overflow: auto;">
        <v-col>
            <v-row>
                <v-col cols="auto">
                    <div>Contrat : <span style="font-weight: bold;">{{ seletedContrat['N° Contrat Comp'] }}</span>
                    </div>
                </v-col>
                <v-col cols="auto">
                    <div>Matricule : <span style="font-weight: bold;">{{ seletedContrat['LocdataS_::N° Mat interne']
                            }}</span></div>
                </v-col>
                <v-col cols="auto">
                    <div>Code client : <span style="font-weight: bold;">{{ seletedContrat['Code client']
                            }}</span></div>
                </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
                <v-col align=center>
                    <table style="background-color: #9E9E9E;width: 100%;">
                        <tr v-for="(elem, idx) in dataToShow" :key="idx"
                            :style="{ 'background-color': idx % 2 == 0 ? '#FAFAFA' : '#CFD8DC' }">
                            <td> {{ elem.label }}</td>
                            <td>
                                <div v-if="elem.key.includes('Date')">
                                    {{ formatDate(seletedContrat[elem.key], 'DD/MM/YYYY') }}
                                </div>
                                <div v-else>
                                    {{ seletedContrat[elem.key] }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-col>
        <v-col>
            <!-- <v-row>
                <v-col cols="">
                    <v-btn color="#90A4AE" style="color:white;width: 100%;" variant="flat">
                        Renouveller le contrat
                    </v-btn>
                </v-col>
                <v-col cols="">
                    <v-btn color="#90A4AE" style="color:white;width: 100%;" variant="flat">
                        Créer un ordre de préparation
                    </v-btn>
                </v-col>
                <v-col cols="">
                    <v-btn color="#90A4AE" @click="isReplaceMaterielOpen = true" style="color:white;width: 100%;"
                        variant="flat">
                        Remplacement de vélo
                    </v-btn>
                </v-col>
            </v-row> -->
            <v-row>
                <v-col cols="auto">
                    <v-btn :href="'/#/etat-des-lieux-materiel?search=' + seletedContrat['N° Contrat Comp']"
                        variant="tonal" target="_blank" color="#1A237E">État
                        des lieux</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn :href="'/#/sav-gestion?search=' + seletedContrat['N° Contrat Comp']" color="#1B5E20"
                        variant="tonal" target="_blank">Sav</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn :href="'/#/signature-en-ligne?search=' + seletedContrat['Code client']" color="#B71C1C"
                        style="" variant="tonal" target="_blank">Signature des documents</v-btn>
                </v-col>
            </v-row>
            <v-col cols="12" class="px-0">
                <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat">
                    <v-col cols="12">
                        Statut du materiel
                    </v-col>
                    <v-radio-group inline>
                        <v-radio color="#FDD835" label="En attente" v-model="editableKey['statut']"
                            value="waiting"></v-radio>
                        <v-radio color="#F57C00" label="En préparation" v-model="editableKey['statut']"
                            value="gettingready"></v-radio>
                        <v-radio color="#4CAF50" label="Sortie" v-model="editableKey['statut']" value="out"></v-radio>
                        <v-radio color="#5C6BC0" label="Rentré" v-model="editableKey['statut']" value="in"></v-radio>
                        <!-- <v-radio color="#4CAF50" label="En stock" v-model="editableKey['statut']" value="stock"></v-radio>
                        <v-radio color="#5C6BC0" label="Sortie" v-model="editableKey['statut']" value="sortie"></v-radio> -->

                    </v-radio-group>
                </v-card>
            </v-col>
            <v-col v-if="options && options.find((val) => val.name == 'paiement_proposition')" class="px-0">
                <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                    <v-col cols="12">
                        Paiement effectué par...
                    </v-col>
                    <v-select hide-details variant="outlined" elevation="0"
                        :items="[{ title: 'En attente de paiement' }].concat(options.find((val) => val.name == 'paiement_proposition').value)">
                    </v-select>
                </v-card>
            </v-col>
            <v-col v-if="options && options.find((val) => val.name == 'caution_proposition')" class="px-0">
                <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                    <v-col cols="12">
                        Caution effectué par...
                    </v-col>
                    <v-select hide-details variant="outlined" elevation="0"
                        :items="[{ title: 'En attente de caution' }].concat(options.find((val) => val.name == 'caution_proposition').value)">
                    </v-select>
                </v-card>
            </v-col>
            <v-col class="px-0">
                <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                    <v-col cols="12">
                        Effectuer un renouvellement...
                    </v-col>
                    <v-select hide-details variant="outlined" :items="getContratSelectedInformations" return-object
                        item-title="CAT_Tarif_GM_id::Nom_Grille">
                    </v-select>
                    <div class="mt-2 pa-2" align=center>
                        <v-btn color="#4CAF50" style="color:white" variant="flat">
                            renouveller contrat
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
            <v-col class="px-0">
                <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                    <v-col cols="12">
                        Modifier mon materiel...
                    </v-col>
                    <v-combobox class="edition" return-object item-title="MATRICULE"
                        :items="parcListe.filter((val) => val.SOUS_FAMILLE == seletedContrat['LocdataS_::Genre Machine'])"
                        hide-details label="Matricule" v-model="selectedMatricule" @update:modelValue="matriculeChange">
                    </v-combobox>
                    <v-col v-if="matriculIsFree" class="pb-0" align=center>
                        <div v-if="matriculIsFree.data.disponible == 'false'">
                            <v-chip color="red" style="font-weight: bold;">
                                Le {{ selectedMatricule.MATRICULE }}
                                n'est pas disponible
                            </v-chip>
                        </div>
                        <div v-if="matriculIsFree.data.disponible == 'true'">
                            <v-chip color="green" style="font-weight: bold;">
                                Le
                                {{ selectedMatricule.MATRICULE }}
                                est disponible
                            </v-chip>
                        </div>
                    </v-col>
                    <div class="mt-2 pa-2" align=center>
                        <v-btn color="#4CAF50" style="color:white" variant="flat">
                            Valider mon nouveau materiel
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
            <v-col class="px-0" align="right">
                <v-btn color="#D32F2F" style="color:white" variant="flat">
                    Clôturer le contrat
                </v-btn>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>
import moment from 'moment'
import engine from '../core/DataApi'

export default {
    props: {
        seletedContrat: {},
        parcListe: {},
        options: {},
        contrat_informations: {}
    },
    data() {
        return {
            matriculIsFree: undefined,
            isReplaceMaterielOpenVerif: false,
            isReplaceMaterielOpen: false,
            editableKey: ['LocdataS_::N° Mat interne'],
            editableData: {},
            dataToShow: [
                {
                    label: 'Client',
                    key: 'Societe',
                    type: 'text',
                },
                {
                    label: 'Type de contrat',
                    key: 'LocdataS_::ID_Grille_nom',
                    type: 'text',
                },
                {
                    label: 'Agence de retrait',
                    key: 'Lieu_retrait_nom',
                    type: 'text',
                },
                {
                    label: 'Date de sortie',
                    key: 'LocdataS_::Date Debut Loc',
                    type: 'date',
                    format: 'DD/MM/YYYY'
                },
                {
                    label: 'Date de retour',
                    key: 'LocdataS_::Date retour',
                    type: 'date',
                    format: 'DD/MM/YYYY'
                },
                {
                    label: 'Prix location TTC (€)',
                    key: 'Prix Location Total_TTC',
                    type: 'text',
                },
                {
                    label: 'Acompte (€)',
                    key: 'Acompte_final',
                    type: 'text',
                },
                {
                    label: 'Caution (€)',
                    key: 'caution_multiple::caution_texte',
                    type: 'text',
                },
                {
                    label: 'Etat de la caution',
                    key: 'caution_multiple::etat_caution',
                    type: 'text',
                },
            ]
        }
    },
    methods: {
        matriculeChange() {
            let that = this
            console.log("matriculeChange");
            engine.is_materiel_free({ UID: 'F893548F-92F3-474B-A88B-B1EB3C781E0B ddddd' }, function (data) {
                that.matriculIsFree = data
            })
        },
        formatDate(date, format) {
            return moment(date).format(format)
        },
        changeMatricule() {
            this.isReplaceMaterielOpenVerif = false
        }
    },
    computed: {
        getContratSelectedInformations() {
            let elem = this.contrat_informations.find((val) => val.fieldData.Nom_Genre_Machine == this.seletedContrat['LocdataS_::Genre Machine'])
            console.log("elem", elem);
            if (elem)
                return elem.portalData.CAT_Tarif_GM_id
            else
                return undefined
        },

    },
    mounted() {
        this.selectedMatricule = JSON.parse(JSON.stringify(this.seletedContrat['LocdataS_::N° Mat interne']))
    }
}
</script>

<style></style>