<template>
  <div v-if="isLoading == false">
    <v-dialog v-model="creationContratValidation" width="auto">
      <v-card>
        <v-col class="pb-0">
          <div class="pa-2" style="font-weight: bold;">
            Valider la création de mon contrat ?
          </div>
        </v-col>
        <v-col class="pt-0">
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <v-btn color="green" @click="finalize">
                Oui
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="red" @click="creationContratValidation = false">
                Non
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <div style="position:absolute;right:10px;">
      <v-btn icon="mdi-close-circle" density="small" variant="text" color="#E57373" style="font-size: 20px;"
        @click="close">
      </v-btn>
    </div>
    <v-col>
      <v-col class="mb-0 pb-0" align=center>
        <h1 class="mb-0 pb-0" style="background-color: #7986CB;color: white;  border-radius:10px">
          Création de contrat
        </h1>
      </v-col>
      <Steppy v-model:step="step" doneText="Créer mon contrat" nextText="Suivant" backText="Retour" :tabs="tabs"
        :finalize="function () { creationContratValidation = true }" :primaryColor1="'#7986CB'"
        :primaryColor2="'#ECEFF1'">
        <template #1>
          <v-col cols="12">
            <h3>Informations client</h3>
          </v-col>
          <v-col>
            <v-col cols="12" class="px-0">
              <v-autocomplete v-model="form['client']" class="edition" :items="clientListe" item-title="SOCIETE"
                return-object label="Client sélectionné" hide-details>
              </v-autocomplete>
            </v-col>
            <table v-if="form['client'] != undefined" style="background-color: #9E9E9E;width: 100%;">
              <tr v-for="(elem, idx) in clientKeyToShow" :key="idx"
                :style="{ 'background-color': idx % 2 == 0 ? '#FAFAFA' : '#ECEFF1' }">
                <td align="left" style="font-weight: bold;"> {{ elem.label }}</td>
                <td align="left"> {{ form['client'][elem.key] }}</td>
              </tr>
            </table>
          </v-col>
        </template>
        <template #2>
          <v-col cols="12">
            <h3>Informations Materiel</h3>
          </v-col>
          <v-col cols="12">
            <v-combobox class="edition" v-model="form['family']" return-object item-title="WEB_NOM_FAMILLE"
              :items="familyListe" hide-details label="Famille">
            </v-combobox>
          </v-col>
          <v-col cols="12">
            <v-combobox v-if="form['family']" class="edition" v-model="form['subFamily']" return-object
              item-title="fieldData.titre_1"
              :items="subFamilyListe.filter((val) => val.fieldData['Famille Parc'] == form['family']['Nom_Famille_Principale'])"
              hide-details label="Sous famille">
            </v-combobox>
          </v-col>
        </template>
        <template #3>
          <v-col cols="12">
            <h3>Sélection de Materiel</h3>
          </v-col>
          <v-col>
            <v-col cols="12" class="px-0">
              <v-combobox class="edition" v-model="form['materiel']" return-object item-title="MATRICULE"
                :items="parcListe.filter((val) => val.SOUS_FAMILLE == form['subFamily'].fieldData['Nom_Genre_Machine'])"
                hide-details label="Matricule" @update:modelValue="matriculeChange">
              </v-combobox>
            </v-col>
            <v-col v-if="form['materiel'] != undefined && matriculIsFree" class="pt-0">
              <div v-if="matriculIsFree.data.disponible == 'false'">
                <v-chip color="red" style="font-weight: bold;">
                  Le {{ form['materiel'].MATRICULE }}
                  n'est pas disponible
                </v-chip>
              </div>
              <div v-if="matriculIsFree.data.disponible == 'true'">
                <v-chip color="green" style="font-weight: bold;">
                  Le
                  {{ form['materiel'].MATRICULE }}
                  est disponible
                </v-chip>
              </div>
            </v-col>
            <table v-if="form['materiel'] != undefined" style="background-color: #9E9E9E;width: 100%;">
              <tr v-for="(elem, idx) in matriculKeyToShow" :key="idx"
                :style="{ 'background-color': idx % 2 == 0 ? '#FAFAFA' : '#ECEFF1' }">
                <td align="left" style="font-weight: bold;"> {{ elem.label }}</td>
                <td align="left"> {{ form['materiel'][elem.key] }}</td>
              </tr>
            </table>
          </v-col>
        </template>
        <template #4>
          <v-col cols="12">
            <h3>Type de location</h3>
          </v-col>
          <v-col align=center>
            <v-select v-model="form['subscription']" :items="getContratSelectedInformations.portalData.CAT_Tarif_GM_id"
              return-object item-title="CAT_Tarif_GM_id::Nom_Grille">
            </v-select>
            <v-card class="pa-2" variant="text">
              Prix : {{ form['subscription']['CAT_Tarif_GM_id::T1_Tarif'].toFixed(2) }} €
            </v-card>
          </v-col>
        </template>
        <template #5>
          <v-col cols="12">
            <h3>Lieu de retrait</h3>
          </v-col>
          <v-col>
            <v-select :items="retraitListe" item-title="PR_NOM_AG" return-object v-model="form['rdv']"></v-select>
          </v-col>
        </template>
        <template #6>
          <v-col cols="12">
            <h3>Date de début de location</h3>
          </v-col>
          <v-col>
            <input class="pa-2" style="border: solid 1px lightgrey;border-radius: 5px;" v-model="form['date']"
              type="date">
          </v-col>
        </template>
      </Steppy>
    </v-col>
  </div>
  <v-col v-else align=center class="pa-5">
    <v-progress-circular :size="150" :width="5" color="#7986CB" indeterminate>Chargement</v-progress-circular>
  </v-col>
</template>

<script>

import { Steppy } from 'vue3-steppy'
// import 'vue-step-wizard/dist/vue-step-wizard.css'
import { ref } from "@vue/reactivity";
import { watch } from "vue";
import moment from "moment"
import engine from '../core/DataApi'
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    close: { type: Function },
    get_user_information: {},
    clientListe: {},
    parcListe: {},
    familyListe: {},
    subFamilyListe: {},
    retraitListe: {},
    contrat_informations: {},
  },
  setup() {
    const matriculIsFree = ref(undefined)
    const form = ref({})
    const step = ref(1)
    const tabs = ref([
      {
        key: 'client',
        title: 'Client',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'subFamily',
        title: 'Catégorie',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'materiel',
        title: 'Materiel',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'subscription',
        title: 'Abonnement',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'rdv',
        title: 'RDV',
        iconSuccess: null,
        isValid: false
      },
      {
        key: 'date',
        title: 'Date',
        iconSuccess: null,
        isValid: false
      }
    ])
    const clientKeyToShow = ref([
      { key: 'VILLE', label: 'Ville' },
      { key: 'CONTACT_MAIL(1)', label: 'Mail' },
      { key: 'ADRESSE', label: 'Adresse' },
      { key: 'CODE_POSTAL', label: 'Code postal' },
      { key: 'TELEPHONE', label: 'Téléphone' },
    ])
    const matriculKeyToShow = ref([
      { key: "FAMILLE", label: "Famille" },
      { key: "SOUS_FAMILLE", label: "Sous famille" },
      { key: "NUMERO_SERIE", label: "Numéro de série" },
      { key: "MARQUE", label: "Marque" },
    ]
    )
    const isLoading = ref(false)
    const creationContratValidation = ref(false)

    watch(() => form, (currentValue) => {
      let formKeysTab = Object.keys(currentValue.value)
      for (let i = 0; i < formKeysTab.length; i++) {
        let tmpKey = formKeysTab[i]
        let findedElem = tabs.value.find((val) => val.key == tmpKey)

        if (findedElem) {
          findedElem['isValid'] = true
        }
      }

    },
      { deep: true }
    );

    return { form, step, tabs, clientKeyToShow, matriculKeyToShow, creationContratValidation, isLoading, matriculIsFree }
  },
  components: {
    Steppy
  },
  mounted() {
    this.form['date'] = moment().format('YYYY-MM-DD')
  },
  methods: {
    matriculeChange() {
      let that = this
      console.log("matriculeChange");
      engine.is_materiel_free({ UID: 'F893548F-92F3-474B-A88B-B1EB3C781E0B ddddd' }, function (data) {
        that.matriculIsFree = data
      })
    },
    finalize() {
      let that = this
      this.isLoading = true
      let mandatoryData = {}

      mandatoryData['CLD_CONTROLE_DEPART'] = ""
      mandatoryData['CLD_CONTROLE_RETOUR'] = ""

      mandatoryData['CLD_MATRICULE'] = this.form['materiel'].MATRICULE
      mandatoryData['CLD_DATE_DEPART'] = moment(this.form['date']).format('MM/DD/YYYY')
      mandatoryData['CLD_DATE_RETOUR'] = moment(this.form['date']).add(this.form.subscription['CAT_Tarif_nom_id::duree'], this.form.subscription['CAT_Tarif_nom_id::unite']).format('MM/DD/YYYY')
      mandatoryData['CLD_DESCRIPTION'] = this.form.family['WEB_NOM_FAMILLE']
      mandatoryData['CLD_ID_SOCIETE'] = this.get_user_information.user_id_societe
      mandatoryData['CLD_ID_AGENCE'] = this.get_user_information.user_id_agence

      mandatoryData['CLD_ID_WEB'] = uuidv4()
      mandatoryData['CLD_ID_CLIENT'] = this.form.client.id
      mandatoryData['CLD_ID_LIEU_RETRAIT'] = this.form.rdv.PR_ID_AG
      mandatoryData['CLD_ID_DUREE_LOCATION'] = this.form.subscription['CAT_Tarif_GM_id::ID_Grille']

      mandatoryData['CLD_SOUS_FAMILLE'] = this.form.family.Nom_Famille_Principale
      mandatoryData['CLD_NOM_CLIENT'] = this.form.client.SOCIETE

      engine.creat_contrat(mandatoryData, function (data) {
        that.close(data)
        that.isLoading = false
      })
    }
  },
  computed: {
    getContratSelectedInformations() {
      let elem = this.contrat_informations.find((val) => val.fieldData.Nom_Genre_Machine == this.form.subFamily.fieldData.Nom_Genre_Machine)
      if (elem)
        return elem
      else
        return undefined
    },
    getAccListe() {
      if (this.getSelectedSubFamilyData) {
        return this.getSelectedSubFamilyData.portalData.ComplementLocation
      } else return undefined
    },
    getSubscriptionListe() {
      if (this.getSelectedSubFamilyData) {
        return this.getSelectedSubFamilyData.portalData.CAT_Tarif_GM_id
      } else return undefined
    },
    getSelectedSubFamilyData() {
      if (this.form['subFamily']) {
        return this.contrat_informations.find((val) => val.fieldData.Nom_Genre_Machine == this.form['subFamily'].Nom_Genre_Machine)
      } else
        return undefined
    }
  }
}
</script>

<style></style>