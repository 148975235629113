<template>
  <div v-if="showApp">
    <email-visualisation></email-visualisation>
  </div>
</template>

<script>
import EmailVisualisation from "@/projects/email-visualisation/components/emails-tab.vue";

export default {
  data() {
    return {
      showApp: false,
    };
  },
  mounted() {
    let appsToShow = this.$store.state.webApplicationToShow;
    let currentApp = this.$route.fullPath.replace("/", "").split('?')[0];
    if (appsToShow.includes(currentApp)) this.showApp = true;
  },
  components: {
    EmailVisualisation,
  },
};
</script>

<style>
</style>