<template>
  <div>
    <v-row justify="center" align="center">
      <v-col v-if="isLoading == false" cols="10" sm="6" align="center">
        <v-card max-width="600" style="margin-top: 20vh; border: solid 1px grey" class="pa-3" color="#F5F5F5"
          variant="flat">
          <v-col class="pb-0">
            <h1>Connexion</h1>
            <h4 style="color:grey">ACCEN - Web applications</h4>
            <v-divider class="mt-2"></v-divider>
          </v-col>
          <v-col align="center">
            <v-text-field style="background-color: white" variant="outlined" class="my-2" label="Utilisateur"
              type="text" hide-details v-model="user.email"></v-text-field>
            <v-text-field style="background-color: white" variant="outlined" class="my-2" label="Mot de passe"
              v-model="user.password" type="password" hide-details></v-text-field>
          </v-col>
          <v-col class="pt-0" v-if="invalidUser">
            <v-card color="#EF5350" class="pa-1">
              <div style="color: white; font-weight: bold">
                L'adresse email ou le mot de passe est incorrect
              </div>
            </v-card>
          </v-col>

          <v-col class="pt-0">
            <v-btn @click="login" class="modal-btn" style="background-color: #3f51b5; color: white">
              Se connecter
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
      <v-col v-else align="center" style="margin-top: 40vh">
        <v-progress-circular size="110" indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import engine from "@/core/index";

export default {
  data() {
    return {
      isLoading: true,
      invalidUser: false,
      user: { email: "", password: "" },
      items: [
        {
          text: "Planning Disponibilité",
          href: "planning-disponibilite",
          disabled: false,
        },
        {
          text: "Planning Livraison",
          href: "planning-livraison",
          disabled: false,
        },
        {
          text: "Planning Technicien",
          href: "planning-technicien",
          disabled: false,
        },
        {
          text: "Statistiques",
          href: "statistiques",
          disabled: false,
        },
      ],
    };
  },
  mounted() {
    this.isUserConnected();
  },
  methods: {
    updateUser() {
      let that = this;
      let token = localStorage.getItem("userToken");
      engine.get_user_by_token(token, function (data) {
        if (data.code == 0) {
          let user = data.data.data.data[0].fieldData;
          delete user.user_id;
          delete user.user_password;
          that.$store.state.user = user;
        } else {
          that.$router.push("/login");
        }
        that.isLoading = false;
      });
    },
    isUserConnected() {
      let that = this;
      let token = localStorage.getItem("userToken");
      engine.get_user_by_token(token, function (data) {
        if (data.code == 0) {
          that.goToCalendarPage();
        } else {

          localStorage.setItem("userToken", undefined);
          that.isLoading = false;
        }
      });
    },
    login() {
      let that = this;
      if (this.user.email.length !== 0 && this.user.password.length !== 0)
        engine.user_login(this.user.email, this.user.password, function (data) {
          that.$store.replaceState({});
          if (data.code == 0) {
            localStorage.setItem("userToken", data.data.id);
            that.updateUser();
            that.goToCalendarPage();
          } else {
            that.invalidUser = true;
          }
        });
      else that.invalidUser = true;
    },
    goToCalendarPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>